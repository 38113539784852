import React from "react";
import PropTypes from 'prop-types';
import environment from "lib/environment";
import { Typography ,Button} from "@getgo/chameleon-web-react-wrapper";
import { FormattedMessage, defineMessages } from "react-intl";
import { 
  GotoIcon,
  LockedOutlinedIcon,
  CardDiscoverOutlinedIcon,
  CardMastercardOutlinedIcon,
  CardVisaOutlinedIcon,
  OpenVoiceIcon,
  GotoAssistIcon,
  IdCardOutlinedIcon,
  CardAmexOutlinedIcon 
} from "@getgo/chameleon-icons/react";
import classes from './notification-card.scss';
import st from 'core/shared-translations';
import { Box } from "@mui/material";

const t = defineMessages({
  "notifications.enhanced-security.header": {
    id :"notifications.enhanced-security.header",
    defaultMessage: "Add an extra layer of security",
  },
  "notifications.enhanced-security.content": {
    id: "notifications.enhanced-security.content",
    defaultMessage: "Enable the Enhanced Security setting to verify your login and leverage multi-factor authentication (MFA) when you sign in from you account.",
  },
  "notifications.enhanced-security.Link": {
    id: "notifications.enhanced-security.Link",
    defaultMessage: "Sign In & Security",
  },
  "notifications.userInvitation.header": {
    id: "notifications.userInvitation.header",
    defaultMessage: "Would you like to transfer your products?",
  },
  "notifications.userInvitation.content": {
    id: "notifications.userInvitation.content",
    defaultMessage:"You've been invited to join the account for {name}.",
  },
  "notifications.userInvitation.link": {
    id: "notifications.userInvitation.link",
    defaultMessage: "View invitation",
  },
  "notifications.creditCardExpiring.header":{
    id: "notifications.creditCardExpiring.header",
    defaultMessage: "Credit card expiring",
  },
  "notifications.creditCardExpiring.content": {
    id: "notifications.creditCardExpiring.content",
    defaultMessage:"Your {cardType} ending in ...{last4Digits} expires {cardExpirationMonth}, {expirationYear}. Please update your card to avoid a loss of service.",
  },
  "notifications.creditCardExpiring.link": {
    id: "notifications.creditCardExpiring.link",
    defaultMessage: "Update Credit Card",
  },
  "notifications.creditCardExpired.header": {
    id: "notifications.creditCardExpired.header",
    defaultMessage:"Credit card expired",
  },
  "notifications.creditCardExpired.content": {
    id: "notifications.creditCardExpired.content",
    defaultMessage: "Your {cardType} ending in ...{last4Digits} expired {cardExpirationMonth}, {expirationYear}. Update your card to continue your service.",
  },

  "notifications.trialExpired.content": {
    id: "notifications.trialExpired.content",
    defaultMessage: "Subscribe now to continue using {productDisplayName}.",
  },
  "notifications.trialExpired.link": {
    id: "notifications.trialExpired.link",
    defaultMessage: "Subscribe Now",
  },
  "notifications.trialExpiring.content": {
    id: "notifications.trialExpiring.content",
    defaultMessage: "Subscribe to {productDisplayName} to keep full access.",
  },
});

const cardType = Object.freeze({
  'american-express' : <CardAmexOutlinedIcon />,
  'discover' : <CardDiscoverOutlinedIcon />,
  'mastercard': <CardMastercardOutlinedIcon />,
  'visa' : <CardVisaOutlinedIcon />,
  'id-card': <IdCardOutlinedIcon />
});

const prodType = Object.freeze({
  'gotomeeting':  <GotoIcon />,
  'openvoice': <OpenVoiceIcon />,
  'gotoassist': <GotoAssistIcon />,
});

export const NotificationCard =({
  notificationData, 
  getMonth,
  getClass
  }) =>{
    if(notificationData.setting === 'ENHANCED_SECURITY'){
      return (
        <Box>
          <Box className={classes['notificationCardHeader']}>
            <LockedOutlinedIcon />
            <Typography>
              <FormattedMessage {...t['notifications.enhanced-security.header']} />
            </Typography>
          </Box>
          <Typography>
            <FormattedMessage {...t['notifications.enhanced-security.content']} />
          </Typography>
          <Box className={classes['notificationCardButton']}>
            <a
              data-wa-link=""
              id="enhanced-security-link"
              href={
               `${environment.get().profile.login}`
              }
            >
              <Button variant="neutral">
                <Typography>
                  <FormattedMessage {...t['notifications.enhanced-security.Link']} />
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
      );
    }
    else if (notificationData.invitation){
      return (
        <Box>
          <Box className={classes['notificationCardHeader']}>
            <GotoIcon />
            <Typography>
              <FormattedMessage {...t['notifications.userInvitation.header']} />
            </Typography>
          </Box>
          <Typography>
            <FormattedMessage {...t['notifications.userInvitation.content']} values={{ name: notificationData.name }} />
          </Typography>
          <Box className={classes['notificationCardButton']}>
            <a
              data-wa-link=""
              id="enhanced-security-link"
              href={`${environment.get().api.adminPortal}/acceptinvitation?id=${notificationData.invitation.id}`}
            >
              <Button data-testid="setting-button" variant="neutral">
                <Typography>
                  <FormattedMessage {...t['notifications.userInvitation.link']}  />
                </Typography>
              </Button>
            </a>
          </Box>
        </Box>
      );
    }
    else if(notificationData.type){
      const expirationMonth = getMonth(notificationData.expirationMonth);
      const cardClass = getClass(notificationData.type);
      return (
        <Box className="notification__content_inner">
          {
              notificationData.isCardExpiring
                ? <Box>
                  <Box className={classes['notificationCardHeader']}>
                    {cardType[cardClass]}
                    <Typography tag="h2">
                      <FormattedMessage {...t['notifications.creditCardExpiring.header']} />
                    </Typography>
                  </Box>
                  <Typography>
                    <FormattedMessage
                      {...t['notifications.creditCardExpiring.content']} 
                      values={{
                        cardType: notificationData.type, 
                        last4Digits: notificationData.last4Digits, 
                        cardExpirationMonth: expirationMonth, 
                        expirationYear: notificationData.expirationYear
                      }} />
                  </Typography>
                  <Box className={classes['notificationCardButton']}>
                    <a
                      data-wa-link=""
                      id="creditCard-link"
                      href={`${environment.get().api.billingPortal}/account/${notificationData.billingAccountKey}/paymentmethods`}
                    >
                      <Button 
                        variant="neutral"
                        id="type-button"
                      >
                        <Typography>
                          <FormattedMessage {...t['notifications.creditCardExpiring.link']} />
                        </Typography>
                      </Button>
                    </a>
                  </Box>
                </Box>
                : <Box>
                  <Box className={classes['notificationCardHeader']}>
                    {cardType[cardClass]} 
                    <Typography tag="h2">
                      <FormattedMessage {...t['notifications.creditCardExpired.header']} />
                    </Typography>
                  </Box>
                  <Typography>
                    <FormattedMessage
                      {...t['notifications.creditCardExpired.content']} 
                      values={{
                        cardType: notificationData.type, 
                        last4Digits: notificationData.last4Digits, 
                        cardExpirationMonth: expirationMonth, 
                        expirationYear: notificationData.expirationYear
                      }} />
                  </Typography>
                  <Box className={classes['notificationCardButton']}>
                    <a
                      data-wa-link=""
                      id="creditCard-link"
                      href={`${environment.get().api.billingPortal}/account/${notificationData.billingAccountKey}/paymentmethods`}
                    >
                      <Button
                        variant="neutral"
                        id="type-button2"
                      >
                        <Typography>
                          <FormattedMessage {...t['notifications.creditCardExpiring.link']} />
                        </Typography>
                      </Button>
                    </a>
                  </Box>
                </Box>
            }
        </Box>
      );
    }
    else if (notificationData.productType === 'TRIAL'){
      let productClass;
      const productSpecs = {
        GoToMeeting: 'gotomeeting',
        GoToWebinar: 'gotomeeting',
        GoToTraining: 'gotomeeting',
        Openvoice: 'openvoice',
        GoToAssist: 'gotoassist'
      };
      const productName = notificationData.productDisplayName.split(' ')[0];
      if (productName in productSpecs) {
        productClass = productSpecs[productName];
      } else {
        productClass = 'gotomeeting';
      }
      return (
        <Box className="notification__content_inner">
          {
            notificationData.subscriptionExpired
              ? <Box>
                <Box className={classes['notificationCardHeader']}>
                  {prodType[productClass]}
                  <Typography>
                    <FormattedMessage {...st['shared.trialExpired-Header']} values={{ productDisplayName: notificationData.productDisplayName }} />
                  </Typography>
                </Box>
                <Typography>
                  <FormattedMessage {...t['notifications.trialExpired.content']} values={{ productDisplayName: notificationData.productDisplayName }} />
                </Typography>
                <Box className={classes['notificationCardButton']}>
                  <a
                    data-wa-link=""
                    id="subscription-link"
                    href={`${environment.get().api.billingPortal}/account/${notificationData.billingAccountKey}/subscriptions/configure/${notificationData.key}`}
                  >
                    <Button variant="neutral">
                      <Typography>
                        <FormattedMessage {...t['notifications.trialExpired.link']} />
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Box>
              : <Box>
                <Box className={classes['notificationCardHeader']}>
                  {prodType[productClass]}
                  <Typography>
                    {
                      notificationData.subscriptionExpiringToday
                        ? <FormattedMessage {...st['shared.trialExpiringToday-Header']} values={{ productDisplayName: notificationData.productDisplayName }} />
                        : <FormattedMessage {...st['shared.trialExpiring-Header']} values={{ productDisplayName: notificationData.productDisplayName, daysRemainingInTerm: notificationData.daysRemainingInTerm }} />
                    }
                  </Typography>
                </Box>  
                <Typography>
                  <FormattedMessage {...t['notifications.trialExpiring.content']} values={{ productDisplayName: notificationData.productDisplayName }} />
                </Typography>
                <Box className={classes['notificationCardButton']}>
                  <a
                    data-wa-link=""
                    id="subscription-link"
                    href={`${environment.get().api.billingPortal}/account/${notificationData.billingAccountKey}/subscriptions/configure/${notificationData.key}`}
                  >
                    <Button variant="neutral">
                      <Typography>
                        <FormattedMessage {...t['notifications.trialExpired.link']} />
                      </Typography>
                    </Button>
                  </a>
                </Box>
              </Box>
          }
        </Box>
      );
    }   
};

NotificationCard.propTypes = {
  notificationData: PropTypes.object.isRequired,
  getMonth: PropTypes.func.isRequired,
  getClass: PropTypes.func.isRequired
};

