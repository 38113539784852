import { combineReducers } from 'redux';
import { intlReducer as intl } from 'react-intl-redux';
import me from 'modules/me-module';
import ui from 'modules/ui-module';
import billing from 'modules/billing-module';
import extAdmin from 'modules/extadmin-module';
import mtgService from 'modules/meeting-module';


export const makeRootReducerFactory = ({ combineReducers,intl,me, ui, billing,extAdmin ,mtgService }) =>
  (asyncReducers) => combineReducers({
    // Add sync reducers here
    intl,
    me,
    ui,
    billing,
    extAdmin,
    mtgService,
    ...asyncReducers,
  });

  export const makeRootReducer = makeRootReducerFactory({
    combineReducers,
    intl,
    me,
    ui,
    billing,
    extAdmin,
    mtgService,
});

export default makeRootReducer;
