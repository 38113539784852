import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const billingUrl = { baseURL: environment.get().api.billingURL };
const billingEndpoint = appconfig.endpoints.billingAccountMe;
const paymentEndpoint = appconfig.endpoints.paymentUrl;
const subscriptionEndpoint = appconfig.endpoints.subscriptionUrl;

export const daoBillingMeGet = () => api.get(billingEndpoint, billingUrl);

export const daoSubscriptionMeGet = (key) => api.get(subscriptionEndpoint(key),billingUrl);

export const daoPaymentsMeGet = (key) => api.get(paymentEndpoint(key),billingUrl);

