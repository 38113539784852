import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';

const meEndpointWithUniqueParam = appconfig.endpoints.meWithUniqueParam;
const avatarEndpoint = appconfig.endpoints.avatarUrls;

export const daoMeGet = () => api.get(meEndpointWithUniqueParam);

export const daoAvatarUrlsGet = () => api.get(avatarEndpoint);

