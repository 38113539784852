import React from "react";
import { useDispatch } from "react-redux";
import  GotoAppBannerView  from '../components/gotoapp-banner';
import { showGoToAppBanner } from "modules/ui-module";
import environment from "lib/environment";

export const GotoAppBanner = () => {
  const dispatch = useDispatch();

  const onButtonClick =() =>{
    window.open(environment.get().announcement.GetGoToApp,'_blank', 'noopener,noreferrer');
  };

  const onClose =() =>{
    dispatch(showGoToAppBanner(false));
  };

  return (
    <GotoAppBannerView
      onButtonClick={onButtonClick}
      onClose={onClose}
    />
  );
};

export default GotoAppBanner;
