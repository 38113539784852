import React, { createContext } from 'react';
import { ThemeProvider as ChamWebThemeProvider } from '@getgo/chameleon-web-react-wrapper';
import PropTypes from 'prop-types';

export const AppThemeContext = createContext({ chameleonTheme: 'light' });

export const AppThemeProvider = ({ children }) => {
    if (!localStorage.getItem('THEME')) {
        localStorage.setItem('THEME', 'light');
    }
    const theme = localStorage.getItem('THEME');
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const chameleonTheme = theme === 'os' ? (prefersDarkMode ? 'dark' : 'light') : theme === 'dark' ? 'dark' : 'light';
    return (
      <AppThemeContext.Provider value={{ chameleonTheme }}>
        <ChamWebThemeProvider theme={chameleonTheme} rebranding2021>
          {children}
        </ChamWebThemeProvider>
      </AppThemeContext.Provider>
    );
};

AppThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export default AppThemeProvider;
