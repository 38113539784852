import React from "react";
import PropTypes from 'prop-types';
import ProductContainer from "../containers/product-container";
import { FormattedMessage, defineMessages } from 'react-intl';
import { Typography, Menu ,Button } from '@getgo/chameleon-web-react-wrapper';
import classes from '../components/product-view.scss';
import QuickLinksContainer from "../containers/quick-link-container";
import { GotoMarkIcon} from '@getgo/chameleon-icons/react';
import GotoAssist  from "static/images/svg/g2alogo.svg";
import OpenVoice  from "static/images/svg/openvoice.svg";
import environment from "lib/environment";
import st from 'core/shared-translations';
import { Box } from "@mui/material";

const t = defineMessages({
  "home.quick-links.title": {
    id: "home.quick-links.title",
    defaultMessage: "Quick links",
    description: "Title for quick links container",
  },
  "home.products.contentNoProductAdmin": {
    id : "home.products.contentNoProductAdmin",
    defaultMessage: "You are an account admin. Please navigate to the Admin Center to manage users and account settings.",
  },
  "home.products.contentNoProduct": {
    id: "home.products.contentNoProduct",
    defaultMessage: "You currently don’t have any products.",
  },
  "home.products.addProduct": {
    id: "home.products.addProduct",
    defaultMessage: "ADD A PRODUCT",
  },
});
const HomeView = ({
  isProductFound,
  entitlementsNotSupported,
  meProducts,
  meData,
  meIsAccountAdmin,
  meIsBillingAdmin,
  }) =>{
  return (
    <Box className={classes['home-view']}>
      <Menu label="products-view" style={{ borderRadius: '0px' }}>
        <Box role="menuitem" className={classes["all-subscriptions__tiles-wrapper"]}>
          <Box>
            <Typography variant="heading-medium" tag="h2">
              <FormattedMessage {...st['shared.navbar.products']} />
            </Typography>
          </Box>
          {isProductFound ?
            <Box data-section="my-account-product-container" className={classes["productContainer"]}>
              {meProducts ? 
                meProducts.map(prod => <ProductContainer key={prod} product={prod} />) : null}
            </Box>
          : <Box>
            <Box data-section="my-account-product-container" className={classes["productContainer"]}>
              <Box
                className={classes["productContainer_noproductsLogos"]}
                >
                <GotoMarkIcon size="50px" />
                <img alt="no-product-assist-logo" className={classes['noproductsLogos']} src={GotoAssist} />
                <img alt="no-product-openvoice-logo" className={classes['noproductsLogos']} src={OpenVoice} />
              </Box>
            </Box>
            <Box className={classes["noproducts__message"]}>
              {
                meIsAccountAdmin
                  ? <Typography variant="body-large-strong"><FormattedMessage {...t['home.products.contentNoProductAdmin']} /></Typography>
                  : <Typography variant="body-large-strong"><FormattedMessage {...t['home.products.contentNoProduct']} /></Typography>
              }
            </Box>
            {
              meIsBillingAdmin
                ? 
                  <a
                    data-wa-link=""
                    href={environment.get().help.billing}
                    target="_blank"
                    rel="noopener noreferrer"
                    id={`${entitlementsNotSupported ? 'not-found' : 'no-prod'}`}
                  >
                    <Button className={classes["addproduct"]} variant="neutral">
                      <FormattedMessage {...t['home.products.addProduct']} />
                    </Button>
                  </a>

                : null
              }
          </Box>}      
        </Box>
      </Menu>
      {meData ? 
        <Menu label="quick-links-view" style={{ borderRadius: '0px' ,flex: "1 1 auto"}}>
          <Box role="menuitem" data-section="quick-links-wrapper" className={classes["quick-links-wrapper"]}>
            <Box>
              <Typography variant="heading-medium" tag="h2">
                <FormattedMessage {...t['home.quick-links.title']} />
              </Typography>
            </Box>
            <QuickLinksContainer />
          </Box>
        </Menu>
      :<Box className="noPropsAvailable" />}
    </Box>  
  );
};

HomeView.propTypes= {
  isProductFound: PropTypes.bool.isRequired,
  entitlementsNotSupported: PropTypes.bool.isRequired,
  meIsLoading: PropTypes.bool.isRequired,
  meLoader: PropTypes.bool.isRequired,
  meProducts: PropTypes.array.isRequired,
  meData: PropTypes.object.isRequired,
  meIsBillingAdmin: PropTypes.bool.isRequired,
  meIsAccountAdmin: PropTypes.bool.isRequired,
};

export default HomeView;
