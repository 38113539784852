import React from 'react';
import { ExternalLinkOutlinedIcon,
  AppSwitcherOutlinedIcon,
  PersonOutlinedIcon,
  ProtectedOutlinedIcon,
  CodeOutlinedIcon,
  BellOutlinedIcon , 
  HelpCircleOutlinedIcon,
  ThemesOutlinedIcon,
  ChevronRightOutlinedIcon,
  SignOutOutlinedIcon,
  CheckmarkOutlinedIcon
} from '@getgo/chameleon-icons/react';
import st from 'core/shared-translations';
import auth from "lib/myaccount-auth";
import {
  FormattedMessage,
} from 'react-intl';
import ProductLink from './product-link-view';
import { Menu, MenuItem,Typography,
  IconButton,
  MenuSeparator,
  AccordionItem,
  Accordion
 } from '@getgo/chameleon-web-react-wrapper';
import { Box } from '@mui/material';
import environment from 'lib/environment';
import { useSelector } from 'react-redux';
import { meSupportExperience ,meDisplayName,meUserName, meData,meId,meNewAvatarImageSource} from 'modules/me-module';
import { gotoAppEnabled } from 'modules/meeting-module';
import classes from './main-nav-view.scss';
import { useDispatch } from 'react-redux';
import { showSidePanel,showUnreadLogo ,setTheme} from 'modules/ui-module';

const helpHandledByProductSupport = environment.get().help.handledByProductSupport;
const help = environment.get().help.support;
const terms = environment.get().footer.terms;
const about_us = environment.get().footer.aboutUs;
const policy = environment.get().footer.policy;

export const AvatarDrawer = () => {
  const {
    supportExperience,
    avatarImageUrl,
    displayName,
    displayEmail,
    meData,
    isGotoAppEnabled,
    logout
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();

  const notificationsClick = () =>{   
    dispatch(showSidePanel('Notifications')); 
    dispatch(showUnreadLogo(false));
  };

  const themeClick = (theme) =>{
    localStorage.setItem('THEME', theme);
    dispatch(setTheme(theme));
    return false;
  };

  const theme = localStorage.getItem('THEME');

  const helpLinks = [
    {
      key: 'get-help',
      title: <FormattedMessage {...st['shared.help.get-help']} />,
      href: supportExperience && supportExperience.handledByProduct ? helpHandledByProductSupport : help,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'support',
      title: <FormattedMessage {...st['shared.help.support']} />,
      href: supportExperience && supportExperience.handledByProduct ? helpHandledByProductSupport : help,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'terms-of-service',
      title: <FormattedMessage {...st['shared.navbar.termsOfService']} />,
      href: terms,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'privacy-policy',
      title: <FormattedMessage {...st['shared.navbar.privacyPolicy']} />,
      href: policy,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'about-us',
      title: <FormattedMessage {...st['shared.navbar.aboutUs']} />,
      href: about_us,
      icon: <ExternalLinkOutlinedIcon />
    }
  ];

  return (
    <Menu label="avatar-view">
      <Box className={classes["avatarInfo"]}>
        <IconButton role="button" labelId="mobile.view.avatar">
          <img src={avatarImageUrl} alt="displayEmail" className={classes["navIcon"]} />
        </IconButton>
        <Box>
          <Typography variant="body-small" title={`${displayName}`} color="type-color-default">{`${displayName}`}</Typography>
          <Typography variant="caption-default-02" title={`${displayEmail}`} color="text-02">{`${displayEmail}`}</Typography>
        </Box>
      </Box>
      <MenuSeparator />
      <a href="/" className="selected">
        <MenuItem leadingIcon={<AppSwitcherOutlinedIcon />}>
          <FormattedMessage {...st['shared.navbar.products']} />
        </MenuItem>
      </a>
      <a href={environment.get().profile.info}>
        <MenuItem leadingIcon={<PersonOutlinedIcon />}>
          <FormattedMessage {...st['shared.personal.info']} /> 
        </MenuItem>
      </a>
      <a href={environment.get().profile.login}>
        <MenuItem leadingIcon={<ProtectedOutlinedIcon />}>
          <FormattedMessage {...st['shared.navbar.signinsecurity']} />
        </MenuItem>
      </a>
      <a href={environment.get().profile.devTools}>
        <MenuItem leadingIcon={<CodeOutlinedIcon />}>
          <FormattedMessage {...st['shared.navbar.developerTools']} />
        </MenuItem>
      </a>
      <MenuSeparator />
      <MenuItem id="notificationsClick" onClick={notificationsClick} leadingIcon={<BellOutlinedIcon size="24px" />} trailingIcon={<ChevronRightOutlinedIcon />}>
        <FormattedMessage {...st['shared.navbar.notificationsHeader']} />
      </MenuItem>
      <Accordion>
        <AccordionItem leadingIcon={<ThemesOutlinedIcon />} heading={<FormattedMessage {...st['shared.navbar.theme']} />}>
          <MenuItem
            key="menuLight"
            id="themeClick"
            toggleable
            leadingIcon={theme === 'light' ? <CheckmarkOutlinedIcon /> : undefined}
            data-testid="theme-light-mobile"
            onClick={() => {
                themeClick('light');
            }}
            >
            <FormattedMessage {...st['shared.navbar.themeLight']} />
          </MenuItem>
          <MenuItem
            key="menuDark"
            toggleable
            leadingIcon={theme === 'dark' ? <CheckmarkOutlinedIcon /> : undefined}
            data-testid="theme-dark-mobile"
            onClick={() => {
                themeClick('dark');
            }}
            >
            <FormattedMessage {...st['shared.navbar.themeDark']} />
          </MenuItem>
          <MenuItem
            key="menuAutoSync"
            toggleable
            leadingIcon={theme === 'os' ? <CheckmarkOutlinedIcon /> : undefined}
            data-testid="theme-os-mobile"
            onClick={() => {
                themeClick('os');
            }}
            >
            <FormattedMessage {...st['shared.navbar.themeOs']} />
          </MenuItem>
        </AccordionItem>
      </Accordion>
      <Accordion>
        <AccordionItem leadingIcon={<HelpCircleOutlinedIcon />} heading={<FormattedMessage {...st['shared.navbar.helpHeader']} />}>
          <Menu label="mobile-help-view">
            <Box>
              {helpLinks.map((
                {
                  key, title, icon, href
                }) => (
                  <MenuItem
                    key={key}
                    trailingIcon={icon}
                    onClick={() => {
                      window.open(href);
                    }}
                  >
                    {title}
                  </MenuItem>
                ))}
            </Box>
          </Menu>
        </AccordionItem>
      </Accordion>
      <MenuSeparator />
      {
        meData.entitlements
        ? meData.entitlements.map(product => <ProductLink key={product} product={product} gotoAppEnabled={isGotoAppEnabled} />)
        : null
      }
      {!!meData.entitlements && <MenuSeparator />}
      <a href="#" onClick={logout}>
        <MenuItem leadingIcon={<SignOutOutlinedIcon color="type-color-default" />}>
          <FormattedMessage {...st['shared.navbar.signOut']} />
        </MenuItem>
      </a>
      <MenuSeparator />
      <MenuItem>
        <Typography variant="caption-small-01" color="text-02">
          <FormattedMessage {...st['shared.navbar.copyRight']} />
        </Typography>
      </MenuItem>
      <MenuSeparator />
    </Menu>
  );
};

export const getValuesFromStore = (state) => {
  const avatarBaseImageUrl = environment.get().avatar_image_url || "";
  let avatarImageUrl = avatarBaseImageUrl.length
    ? `${avatarBaseImageUrl}${meId(state)}_medium.jpg`
    : "";
  // Check if the user has uploaded a new avatar image during the session.
  //   If he/she did, use the URL of that image as a source for the mini avatar.
  const newAvatarImage = meNewAvatarImageSource(state);
  if (newAvatarImage) {
    avatarImageUrl = newAvatarImage;
  }
  return {
    supportExperience: meSupportExperience(state),
    avatarImageUrl,
    displayEmail: meUserName(state) || "",
    displayName: meDisplayName(state) || "",
    meData: meData(state),
    isGotoAppEnabled: gotoAppEnabled(state),
    logout: auth.logout.bind(auth),
  };
};

export default AvatarDrawer;
