import React from 'react';
import PropTypes from "prop-types";
import { defineMessages, injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import classes from '../components/product-view.scss';
import GetProductFamilyIcon from 'lib/product-icons';
import {
  Typography, Button, Skeleton
} from '@getgo/chameleon-web-react-wrapper';
import environment from 'lib/environment';
import RenderLicense from '../components/render-add-license';
import { Box } from "@mui/material";

const t = defineMessages({
  "products.meetNow": {
    id: "products.meetNow",
    defaultMessage: "Start instant meeting",
    description: "Title for Instant Meeting link in GotoMeeting",
  },
  "products.goto-webinar.schedule": {
    id: "products.goto-webinar.schedule",
    defaultMessage: "Launch GoTo Webinar",
    description: "Title for G2W links in products card",
  },
  "products.goto-training.schedule": {
    id: "products.goto-training.schedule",
    defaultMessage: "Launch GoTo Training",
    description: "Title for G2T links in products card",
  },
  "products.jiveAdministration": {
    id: "products.jiveAdministration",
    defaultMessage: "Administration",
    description: "Title for link in jive product",
  },
  "products.jiveBilling": {
    id:"products.jiveBilling",
    defaultMessage: "Billing",
    description: "Title for link in jive billing",
  },
  "products.launchGoToResolveMDM": {
    id: "products.launchGoToResolveMDM",
    defaultMessage: "Launch MDM",
    description: "Title for link in Launch MDM",
  },
});

const ProductView = ({
  prod,
  productInfo,
  handleInstantMeetingClickEvent,
  theme,
  addLicense,
  instantMeetingDisabled,
  isloading
  }) =>{
  return (
    <Box className={`${classes["subscription-card"]} ${classes[prod]}`} data-testid="subscription-card">
      <section className={classes["subscription-card__header"]} data-testid="subscription-card__header">
        <Box className={classes["subscription-card__badge-icon"]}>
          <Box className={classes["subscription-card__product-icon"]} data-testid="subscription-card__product-icon">
            <GetProductFamilyIcon
              productFamily={prod}
              iconSize="100px"
              theme={theme} />
          </Box>
        </Box>
        <Box className={classes["subscription-card__product-name"]}>
          <Typography
            variant="heading-small"
            data-testid="subscription-card__product-name"
          >
            {productInfo.prodBold}
          </Typography>
          <Typography
            variant="heading-small"
            data-testid="subscription-card__product-name"
          >
            {productInfo.prodLight}
          </Typography>
        </Box>
        {
          productInfo.subProdName
            ? <Box className={classes["prodLogos__subProd"]} id={`${productInfo.prodClass}-subProd-text`}>
              <Typography variant="heading-small">{productInfo.subProdName}</Typography>
            </Box>
            : null
        }
        <Box className={classes["subscription-card_buttons"]}>
          {(() => {
            switch (productInfo.prodName) {
              case 'GoToMeeting': return (
                <Button
                  variant="neutral"
                  data-wa-link=""
                  className="linkButton"
                  role="button"
                  disabled={instantMeetingDisabled}
                  id="GotoMeeting-MeetNow-Container"
                  onClick={() => {  handleInstantMeetingClickEvent(productInfo.prodName);}}
                >
                  <FormattedMessage {...t['products.meetNow']} />
                </Button>  
              );
              case 'GoToWebinar': return (
                <a
                  data-wa-link=""
                  href={environment.get().product[`${productInfo.prodName}Schedule`]}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`${productInfo.prodName}-Schedule-Container`}
                >
                  <Button variant="neutral">
                    <FormattedMessage {...t['products.goto-webinar.schedule']} />
                  </Button>
                </a>
              );
              case 'GoToTraining': return (
                <a
                  data-wa-link=""
                  href={environment.get().product[`${productInfo.prodName}Schedule`]}
                  target="_blank"
                  rel="noopener noreferrer"
                  id={`${productInfo.prodName}-Schedule-Container`}
                >
                  <Button variant="neutral">
                    <FormattedMessage {...t['products.goto-training.schedule']} />
                  </Button>
                </a>
              );
              default: return null;
            }
          })()}
          {
            productInfo.linkText
              ? 
              (productInfo.prodName === 'GoToMeeting' ||productInfo.prodName === 'GoToTraining' || productInfo.prodName === 'GoToWebinar' )
                ? <a
                    data-wa-link=""
                    href={environment.get().product[productInfo.prodName]} target="_blank" rel="noopener noreferrer" id={`${productInfo.prodName}-Container`}>
                  <Button className={classes['subscription-card_link-text-button']} variant="tertiary">
                    <FormattedMessage id={productInfo.linkText} />
                  </Button>
                </a>
                : <a data-wa-link="" href={environment.get().product[productInfo.prodName]} target="_blank" rel="noopener noreferrer" id={`${productInfo.prodName}-Container`}>
                  <Button className={classes['subscription-card_link-text-button']} variant="neutral">
                    <FormattedMessage id={productInfo.linkText} />
                  </Button>
                </a>
              : 
              null
          }
          {productInfo.showJiveAdminContent
            ? <Box>
              {
                productInfo.showAdminSection
                  ? <span>
                    <a
                      data-wa-link=""
                      href={environment.get().product[`${productInfo.prodName}Administrator`]}
                      target="_blank" rel="noopener noreferrer" id={`${productInfo.prodName}-JiveAdministration-Container`}  
                    >
                      <Button variant="neutral">
                        <FormattedMessage {...t['products.jiveAdministration']} />
                      </Button>
                    </a>
                  </span>
                  : null
              }
              {
                productInfo.showBillingSection
                  ? 
                    <a
                      data-wa-link=""
                      href={environment.get().product[`${productInfo.prodName}Billing`]}
                      target="_blank"
                      rel="noopener noreferrer"
                      id={`${productInfo.prodName}-JiveBilling-Container`}
                  >
                      <Button style={{ paddingTop: '5px' }} variant="neutral">
                        <FormattedMessage {...t['products.jiveBilling']} />
                      </Button>
                    </a>
                  : null
              }
            </Box>
            : null}
          {productInfo.showLaunchMDMLink
            &&  
              <a
                data-wa-link=""
                href={environment.get().product[`${productInfo.prodName}LaunchMDM`]}
                target="_blank"
                rel="noopener noreferrer"
                id={`${productInfo.prodName}-MDM-Container`}
            >
                <Button variant="neutral" style={{ paddingTop: "5px" }}>
                  <FormattedMessage {...t['products.launchGoToResolveMDM']} />
                </Button>
              </a>}
        </Box>
        {
         productInfo.showAddLicenseButton  
          && ( isloading ? <Skeleton variant="rectangle" /> :
             <Box>
              <RenderLicense addLicense={addLicense} />
            </Box>)
        }
      </section>
    </Box>      
  );
};

ProductView.propTypes= {
 prod: PropTypes.string.isRequired,
 productInfo: PropTypes.object.isRequired,
 handleInstantMeetingClickEvent: PropTypes.func.isRequired,
 theme: PropTypes.string.isRequired,
 addLicense: PropTypes.object.isRequired,
 instantMeetingDisabled: PropTypes.bool.isRequired,
 isloading: PropTypes.bool.isRequired,
};

export default injectIntl(ProductView);