import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Banner, Button} from '@getgo/chameleon-web-react-wrapper';
import {
  GotoIcon, ArrowRightFilledIcon, GotoBlackMonochromeFaviconIcon,
} from '@getgo/chameleon-icons/react';
import PropTypes from 'prop-types';
import classes from './gotoapp-banner.scss';
import { Box } from '@mui/material';

const t = defineMessages({
  "gotoappbanner.buttonContent": {
    id:  "gotoappbanner.buttonContent",
    defaultMessage:"Get the new GoTo",
  },
  "gotoappbanner.messageContent": {
    id: "gotoappbanner.messageContent",
    defaultMessage: "<b>New look, new features, even a new app.</b> Discover the next version of GoTo Meeting.",
  },
});

export const GotoAppBannerView =({
  onButtonClick,
  onClose }) => {
  return (
    <Banner
      variant="marketing"
      className={classes['gotoBanner']}
      icon={
        <Box className={classes['gotoBannerIcon']}>
          <GotoIcon />
          <ArrowRightFilledIcon />
          <GotoBlackMonochromeFaviconIcon />
        </Box>
      }
      action={
        <Button
          className="download-gotoapp-button"
          data-test="download-gotoapp-button"
          id="download-gotoapp-button"
          onClick={onButtonClick}>
          <FormattedMessage {...t['gotoappbanner.buttonContent']} />
        </Button>
      }
      onClose={onClose}
      closable
    >
      <FormattedMessage {...t['gotoappbanner.messageContent']} values={{b: chunks => <b>{chunks}</b>}} />
    </Banner>
  ); 
};

GotoAppBannerView.propTypes ={
  onButtonClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default GotoAppBannerView;
