import {
  daoExtMeetingServiceSettingsGet,
  daoMeetingUserSettingsGet,
  daoMeetingConferences
} from 'dao/meetingservice-dao';
import {removeHandledErrorCodes, setHandledErrorCodes} from 'lib/error-message-handling';

// Need to handle (hide) the case when the user does not have G2M, and a call to the
//     Meeting Service API results in an 403 - access.denied error.
const getErrorCodesArray = () => {
  return [
    403,
    'access.denied',
    409,
    'Conflict',
  ];
};

// ------------------------------------
// Constants
// ------------------------------------
export const MTGSVC_SETTINGS_GET = 'MTGSVC_SETTINGS_GET';
export const MTGSVC_SETTINGS_GET_SUCCESS = 'MTGSVC_SETTINGS_GET_SUCCESS';
export const MTGSVC_SETTINGS_GET_FAILURE = 'MTGSVC_SETTINGS_GET_FAILURE';


export const MTG_CONFERENCES_POST = 'MTG_CONFERENCES_POST';
export const MTG_CONFERENCES_POST_SUCCESS = 'MTG_CONFERENCES_POST_SUCCESS';
export const MTG_CONFERENCES_POST_FAILURE = 'MTG_CONFERENCES_POST_FAILURE';
// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
      gotoAppEnabled: false
  },

  // generic or global properties go here
  isLoading: false,
  meetingData: {},
};

// ------------------------------------
// Selectors
// ------------------------------------
export const mtgServiceSettingsIsLoading = (state) => state.mtgService.isLoading;
export const mtgServiceSettingData = (state) => state.mtgService;


export const mtgMeetingData = (state)=> state.mtgService.meetingData;

export const gotoAppEnabled = (state) => {
  const mtgServiceSettings = mtgServiceSettingData(state);
  return (mtgServiceSettings && mtgServiceSettings.gotoAppEnabled) || false;
};

// ------------------------------------
// Actions
// ------------------------------------
export const mtgServiceSettingsGetSuccess = (payload = {}) => ({
  type: MTGSVC_SETTINGS_GET_SUCCESS,
  payload,
});

export const mtgServiceSettingsGetFailure = (payload = {}) => ({
  type: MTGSVC_SETTINGS_GET_FAILURE,
  payload,
});

export const mtgServiceSettingsGet = () => (dispatch) => {
  dispatch({
    type: MTGSVC_SETTINGS_GET,
  });
  setHandledErrorCodes(getErrorCodesArray());
  return daoExtMeetingServiceSettingsGet()
    .then(
      response => dispatch(mtgServiceSettingsGetSuccess(response.data)),
      ex => dispatch(mtgServiceSettingsGetFailure(ex)),
    );
};




export const mtgConferencesGetSuccess = (payload = {}) => ({
  type: MTG_CONFERENCES_POST_SUCCESS,
  payload,
});

export const mtgConferencesGetFailure = (payload = {}) => ({
  type: MTG_CONFERENCES_POST_FAILURE,
  payload,
});

export const mtgConferencesGet = (accountKey,params) => (dispatch) => {
  dispatch({
    type: MTG_CONFERENCES_POST,
  });
  setHandledErrorCodes(getErrorCodesArray());
  return daoMeetingConferences(accountKey,params)
    .then( 
      response => dispatch(mtgConferencesGetSuccess(response.data)),
      ex => dispatch(mtgConferencesGetFailure(ex)),
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [MTGSVC_SETTINGS_GET]: (state) => ({...state, isLoading: true}),
  [MTGSVC_SETTINGS_GET_SUCCESS]: (state, action) => {
    removeHandledErrorCodes(getErrorCodesArray());
    if (typeof action.payload === 'object') {
      return {
        ...state,
        gotoAppEnabled: action.payload.gotoAppEnabled,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [MTGSVC_SETTINGS_GET_FAILURE]: (state) => {
    removeHandledErrorCodes(getErrorCodesArray());
    return {
      ...state,
      isLoading: false,
    };
  },

  
  [MTG_CONFERENCES_POST]: (state) => ({...state, isLoading: true}),
  [MTG_CONFERENCES_POST_SUCCESS]: (state, action) => {
    removeHandledErrorCodes(getErrorCodesArray());
    if (typeof action.payload === 'object') {
      return {
        ...state,
        meetingData: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [MTG_CONFERENCES_POST_FAILURE]: (state) => {
    removeHandledErrorCodes(getErrorCodesArray());
    return {
      ...state,
      isLoading: false,
    };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const mtgServiceReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default mtgServiceReducer;
