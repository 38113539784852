import {
  daoBillingMeGet,
  daoPaymentsMeGet,
  daoSubscriptionMeGet,
} from 'dao/billing-dao';
import {
  setHandledErrorCodes,
  removeHandledErrorCodes
} from 'lib/error-message-handling';

// ------------------------------------
// Constants
// ------------------------------------
export const BILLING_ME_GET = 'BILLING_ME_GET';
export const BILLING_ME_GET_SUCCESS = 'BILLING_ME_GET_SUCCESS';
export const BILLING_ME_GET_FAILURE = 'BILLING_ME_GET_FAILURE';

export const PAYMENT_ME_GET = 'PAYMENT_ME_GET';
export const PAYMENT_ME_GET_SUCCESS = 'PAYMENT_ME_GET_SUCCESS';
export const PAYMENT_ME_GET_FAILURE = 'PAYMENT_ME_GET_FAILURE';

export const SUBSCRIPTION_ME_GET = 'SUBSCRIPTION_ME_GET';
export const SUBSCRIPTION_ME_GET_SUCCESS = 'SUBSCRIPTION_ME_GET_SUCCESS';
export const SUBSCRIPTION_ME_GET_FAILURE = 'SUBSCRIPTION_ME_GET_FAILURE';

export const SUBSCRIPTION_ME_PUT = 'SUBSCRIPTION_ME_PUT';

const getErrorCodesArray = () => {
  return [
    500,
    'internal.error',
  ];
};

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  data: {
    billingAccounts: [],
  },
  subscriptionData: [],
  paymentData:{},
  // generic or global properties go here
  isLoading: false,
};

// ------------------------------------
// Selectors
// ------------------------------------
export const billingIsLoading = (state) => state.billing.isLoading;
export const billingMeData = (state) => state.billing.data;

export const billingMeAccounts = (state) => {
  const billingData = billingMeData(state);
  return (billingData && billingData.billingAccounts) || [];
};

export const meIsBillingAdmin = (state) => {
  const billingAccounts = billingMeAccounts(state);
  return (
    billingAccounts 
    && billingAccounts
    .some(bA => bA.roles.indexOf('ROLE_COMMERCE_BILLING_ADMIN') > -1 || bA.roles.indexOf('ROLE_CMRC_UPDATE_BILLING_ACCOUNT') > -1)) || false;
};

export const mePaymentData = (state) => state.billing.paymentData;
// ------------------------------------
// Actions
// ------------------------------------
export const billingMeReceiveGetSuccess = (payload = {}) => ({
  type: BILLING_ME_GET_SUCCESS,
  payload,
});

export const billingMeReceiveGetFailure = (payload = {}) => ({
  type: BILLING_ME_GET_FAILURE,
  payload,
});

export const billingMeRequestGet = () => (dispatch) => {
  setHandledErrorCodes(getErrorCodesArray());
  dispatch({
    type: BILLING_ME_GET,
  });

  return daoBillingMeGet()
    .then(
      response => dispatch(billingMeReceiveGetSuccess(response.data)),
      ex => dispatch(billingMeReceiveGetFailure(ex)),
    ).finally(
      () => removeHandledErrorCodes(getErrorCodesArray())
    );
};

export const subscriptionMeReceiveGetSuccess = (payload = {}) => ({
  type: SUBSCRIPTION_ME_GET_SUCCESS,
  payload,
});

export const subscriptionMeReceiveGetFailure = (payload = {}) => ({
  type: SUBSCRIPTION_ME_GET_FAILURE,
  payload,
});

export const subscriptionMeRequestGet = (key) => (dispatch) => {
  setHandledErrorCodes(getErrorCodesArray());
  dispatch({
    type: SUBSCRIPTION_ME_GET,
  });

  return daoSubscriptionMeGet(key)
    .then(
      response => dispatch(subscriptionMeReceiveGetSuccess(response.data)),
      ex => {dispatch(subscriptionMeReceiveGetFailure(ex));},
    ).finally(
      () => removeHandledErrorCodes(getErrorCodesArray())
    );
};

export const paymentMeReceiveGetSuccess = (payload = {}) => ({
  type: PAYMENT_ME_GET_SUCCESS,
  payload,
});

export const paymentMeReceiveGetFailure = (payload = {}) => ({
  type: PAYMENT_ME_GET_FAILURE,
  payload,
});

export const paymentMeRequestGet = (key) => (dispatch) => {
  setHandledErrorCodes(getErrorCodesArray());
  dispatch({
    type: PAYMENT_ME_GET,
  });

  return daoPaymentsMeGet(key)
    .then(
      response => dispatch(paymentMeReceiveGetSuccess(response.data)),
      ex => dispatch(paymentMeReceiveGetFailure(ex)),
    ).finally(
      () => removeHandledErrorCodes(getErrorCodesArray())
    );
};

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [BILLING_ME_GET]: (state) => ({...state, isLoading: true}),
  [BILLING_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        data: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [BILLING_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [SUBSCRIPTION_ME_GET]: (state) => ({...state, isLoading: true}),
  [SUBSCRIPTION_ME_GET_SUCCESS]: (state, action) => {

    if (typeof action.payload === 'object') {
      return {
        ...state,
        subscriptionData: action.payload.subscriptions,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [SUBSCRIPTION_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [PAYMENT_ME_GET]: (state) => ({...state, isLoading: true}),
  [PAYMENT_ME_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        paymentData: action.payload,
        isLoading: false,
      };
    }
    return {...state, isLoading: false};
  },
  [PAYMENT_ME_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

};



// ------------------------------------
// Reducer
// ------------------------------------
const billingAccountReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default billingAccountReducer;
