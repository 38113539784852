import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import {
  FormattedMessage,
  defineMessages,
} from "react-intl";
import classes from "./alert.scss";
import appconfig from "config/appconfig";
import { Button ,Typography } from "@getgo/chameleon-web-react-wrapper";
import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  "alert.auth.warning.message": {
    id: "alert.auth.warning.message",
    defaultMessage: "Your session is about to expire. Save your work and reload the page.",
  },
  "alert.auth.warning.time": {
    id: "alert.auth.warning.time",
    defaultMessage: "{timeLeft} seconds remaining.",
  },
  "alert.auth.warning.buttonReload": {
    id: "alert.auth.warning.buttonReload",
    defaultMessage: "Reload",
  },
});

export const AlertAuthWarning = ({
  handleAlertDismiss,
  handleReload,
  timeLeft,
}) => (
  <Alert
    type={appconfig.alertTypes.WARNING}
    icon={<AlertOutlinedIcon/>}
    body={
      <Typography color="type-color-warning">
      <span className={classes.bannerMessageText} role="timer" aria-live="off">
        <FormattedMessage {...t["alert.auth.warning.message"]} />
        <span className={classes.secondPartOfText}>
          {timeLeft && (
            <FormattedMessage
              {...t["alert.auth.warning.time"]}
              values={{ timeLeft: <strong>{timeLeft}</strong> }}
            />
          )}
        </span>
      </span>
      </Typography>
    }
    buttons={
      <Button
        id="auth-warning-reload"
        size="small"
        variant="neutral"
        className={classes.bannerMessageCTA}
        onClick={handleReload}
      >
        <Typography color="type-color-warning">
          <FormattedMessage {...t["alert.auth.warning.buttonReload"]} />
        </Typography>
      </Button>
    }
    handleDismiss={handleAlertDismiss}
  />
);

AlertAuthWarning.propTypes = {
  timeLeft: PropTypes.number,
  handleAlertDismiss: PropTypes.func.isRequired,
  handleReload: PropTypes.func.isRequired,
};

export default AlertAuthWarning;
