import React from 'react';
import PropTypes from 'prop-types';
import {
  FormattedMessage,
  injectIntl,
} from 'react-intl';
import st from 'core/shared-translations';
import {
  HelpCircleOutlinedIcon,
  SignOutOutlinedIcon,
  MoreMenuHamburgerOutlinedIcon,
  CloseOutlinedIcon,
  ChevronRightOutlinedIcon,
  GotoBlackYellowIcon,
  GotoWhiteYellowIcon,
  BellFilledIcon,
  BellOutlinedIcon,
  HelpCircleFilledIcon,
  ThemesOutlinedIcon,
  CheckmarkOutlinedIcon,
} from '@getgo/chameleon-icons/react';
import classes from './main-nav-view.scss';
import { IconButton,MenuItem, Menu, MenuSeparator, PopoverV2, Typography,TooltipV3 ,BadgeCounter ,Avatar } from '@getgo/chameleon-web-react-wrapper';
import { Box } from '@mui/material';

export const MainNav = ({
  logout,
  displayName,
  avatarImageUrl,
  displayEmail,
  showMenu,
  toggleMenuVisibility,
  notificationsClick,
  helpIconClick,
  sidePanel,
  theme,
  meTheme,
  themeClick,
  meShowUnreadLogo,
  notificationsCount,
  meSupportExperience
}) => {
  return (
    <Menu label="main-nav-bar" className={classes['navContainer']} role="menu" aria-label="navbar">
      <Box role="menuitem" className={classes["gotoIcon"]}>
        {(meTheme === 'light') ? <GotoBlackYellowIcon size="42px" /> : <GotoWhiteYellowIcon size="42px" />}
        <Typography variant="body-large" size="20px">
          <FormattedMessage {...st["shared.navbar.my-account"]} />
        </Typography>
      </Box>  
      <Box role="menuitem" className={classes['iconGroup']}>
        <Box className={classes['notificationsIcon']}>
          <IconButton
            id="topbar-notification-icon"
            labelId="topbar-notification-icon"
            data-testid="topbar-notification-icon"
            onClick={notificationsClick}
            >
            {sidePanel === 'Notifications' ? <BellFilledIcon size="24px" /> : <BellOutlinedIcon size="24px" />}
            {
                meShowUnreadLogo
                ? <BadgeCounter label="new notifications" size="small">
                  {notificationsCount}
                </BadgeCounter>
                : null
              }
          </IconButton>
        </Box>
        <TooltipV3 triggerId="topbar-icon">
          <FormattedMessage {...st['shared.navbar.notificationsHeader']} />
        </TooltipV3>
        {meSupportExperience && meSupportExperience.limited ? null:
        <Box className={classes["supportLinks"]}>
          <IconButton 
            id="help-trigger" 
            data-testid="help-trigger"
            labelId="Help" 
            onClick={helpIconClick}
            >
            {sidePanel === 'Help' ? <HelpCircleFilledIcon size="24px" /> : <HelpCircleOutlinedIcon size="24px" />}
          </IconButton>
        </Box>}
        <Box>
          <Box className={classes["avatar-nav"]}>
            <Avatar size="small" variant="secondary" id="user-option-trigger">
              <img 
                alt="userAvatar"
                src={avatarImageUrl}
                title={displayName}
            />
            </Avatar>
          </Box> 
          <Box className={`${classes["fluidOptions"]}`}>
            <IconButton
              labelId="mobile-view-nav-icon"
              id="hamburger-option-trigger"
              data-testid="hamburger-option-trigger"
              role="button"
              onClick={toggleMenuVisibility}
              >
              {!showMenu && <MoreMenuHamburgerOutlinedIcon style={{ paddingBottom: "15px"}} size="30px" />}
              {showMenu && <CloseOutlinedIcon style={{ paddingBottom: "15px"}} size="30px" />}
            </IconButton>    
          </Box>
          <PopoverV2
            menu
            width={280}
            position="bottom-end"
            triggerId="user-option-trigger"
          >
            <Box className={classes["avatarInfo"]}>
              <IconButton role="button" labelId="emailDisplay">
                <img src={avatarImageUrl} alt="displayEmail" className={classes["navIcon"]} />
              </IconButton>
              <Box>
                <Typography variant="body-small" title={`${displayName}`} color="type-color-default">{`${displayName}`}</Typography>
                <Typography variant="caption-default-02" title={`${displayEmail}`} color="text-02">{`${displayEmail}`}</Typography>
              </Box>
            </Box>
            <MenuSeparator />
            <MenuItem
              key="menuBeta"
              leadingIcon={<ThemesOutlinedIcon />}
              trailingIcon={<ChevronRightOutlinedIcon />}
              data-testid="theme-trigger"
              id="theme-trigger"
              onClick={(e)=> e.preventDefault()}
              >
              <FormattedMessage {...st['shared.navbar.theme']} />
            </MenuItem>
            <PopoverV2
              menu
              width={280}
              position="left-center"
              triggerId="theme-trigger"
              >
              <Menu label="theme-menu">
                <MenuItem
                  key="menuLight"
                  toggleable
                  leadingIcon={theme() === 'light' ? <CheckmarkOutlinedIcon /> : undefined}
                  data-testid="theme-light-main"
                  id="theme-light-main"
                  onClick={() => {
                        themeClick('light');
                  }}
                  >
                  <FormattedMessage {...st['shared.navbar.themeLight']} />
                </MenuItem>
                <MenuItem
                  key="menuDark"
                  toggleable
                  leadingIcon={theme() === 'dark' ? <CheckmarkOutlinedIcon /> : undefined}
                  data-testid="theme-dark-main"
                  id="theme-dark-main"
                  onClick={() => {
                        themeClick('dark');
                  }}
                  >
                  <FormattedMessage {...st['shared.navbar.themeDark']} />
                </MenuItem>
                <MenuItem
                  key="menuAutoSync"
                  toggleable
                  data-testid="theme-os-main"
                  id="theme-os-main"
                  leadingIcon={theme() === 'os' ? <CheckmarkOutlinedIcon /> : undefined}
                  onClick={() => {
                        themeClick('os');
                  }}
                  >
                  <FormattedMessage {...st['shared.navbar.themeOs']} />
                </MenuItem>
              </Menu>
            </PopoverV2>
            <MenuItem onClick={logout} leadingIcon={<SignOutOutlinedIcon color="type-color-default" />}>
              <FormattedMessage {...st['shared.navbar.signOut']} />
            </MenuItem>
            <MenuSeparator />
            <MenuItem>
              <Typography variant="caption-small-01" color="text-02">
                <FormattedMessage {...st['shared.navbar.copyRight']} />
              </Typography>
            </MenuItem>
          </PopoverV2>
        </Box>
      </Box>
    </Menu> 
  );
};

MainNav.propTypes = {
  intl: PropTypes.object.isRequired,
  logout: PropTypes.func.isRequired,
  displayName: PropTypes.string.isRequired,
  meIsAccountAdmin: PropTypes.bool.isRequired,
  myProducts: PropTypes.array.isRequired,
  avatarImageUrl: PropTypes.string.isRequired,
  meIsBillingAdmin: PropTypes.bool.isRequired,
  displayEmail: PropTypes.string.isRequired,
  showSupportLink: PropTypes.bool.isRequired,
  supportUrls: PropTypes.object.isRequired,
  gotoAppEnabled: PropTypes.bool.isRequired,
  language: PropTypes.string.isRequired,
  showMenu: PropTypes.bool.isRequired,
  toggleMenuVisibility: PropTypes.func.isRequired,
  notificationsClick: PropTypes.func.isRequired,
  helpIconClick: PropTypes.func.isRequired,
  sidePanel: PropTypes.string.isRequired,
  theme: PropTypes.func.isRequired,
  meTheme: PropTypes.string.isRequired,
  meData: PropTypes.object.isRequired,
  notificationsCount: PropTypes.number.isRequired,
  themeClick: PropTypes.func.isRequired,
  meShowUnreadLogo: PropTypes.bool.isRequired,
  meSupportExperience: PropTypes.object.isRequired,
};

export default injectIntl(MainNav);
