import React, { useCallback, useEffect } from "react";
import {
  showErrorBanner,
  showUnreadLogo, 
  meIsLoading,
  meLoader, 
} from "modules/ui-module";
import { useDispatch,useSelector } from 'react-redux';
import HomeView from '../components/home-view';
import { 
  meData,
  meEmail, 
  meProducts ,
  meUpateNotificationsCount, 
  meNotificationsCount, 
  meIsAccountAdmin, 
  meUpdateNotificationsData, 
  meNotificationsData,
  isProductFound,
  entitlementsNotSupported
} from "modules/me-module";
import { 
  billingMeAccounts, 
  billingMeData, 
  subscriptionMeRequestGet , 
  paymentMeRequestGet, 
  meIsBillingAdmin
} from 'modules/billing-module';
import { creditCardExpirationDetails } from "lib/utility-functions";

export const HomeContainer = () => {
  const {
    meData,
    meIsLoading,
    meLoader,
    meProducts,
    billingMeAccounts,
    meIsAccountAdmin,
    meIsBillingAdmin,
    notificationsData,
    isProductFound,
    entitlementsNotSupported
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();

  const handlePaymentCall = useCallback((billingAccounts) =>{
    
    dispatch(paymentMeRequestGet(billingAccounts[0].key)).then((PaymentData) => {
      let paymentDetails;
      const userAccount = PaymentData.payload;
      for (paymentDetails in userAccount) {
        const data = userAccount[paymentDetails];
        if (data.type === 'CREDIT_CARD') {
          data.creditCard.billingAccountKey = data.billingAccountKey;
          const cardData = creditCardExpirationDetails(data.creditCard);
          // Update card data if necessary
          if (cardData.isCardExpiring || cardData.isCardExpired) {
            const newCardData = [...notificationsData.cardData, cardData];
            dispatch(meUpateNotificationsCount('increament'));
            dispatch(meUpdateNotificationsData(newCardData,'card'));
          }
        }
      }
    }).catch((error) => {
      dispatch(showErrorBanner(true));
      console.error(`error payment ${error}`);
    });
  },[dispatch,notificationsData]);

  const handleSubscriptionCall = useCallback((billingMeAccounts) => {
    const billingAccounts = billingMeAccounts;
    dispatch(subscriptionMeRequestGet(billingAccounts[0].key)).then((SubscriptionData) => {
      let userSubscriptionData = [];
      const subscriptions = SubscriptionData.payload[0].subscriptions;
      // we will start showing notifications when there 30 or less days are left in user's subscription
      const MIN_DAYS_FOR_NOTIFICATION = 30;
      if (subscriptions) {
        subscriptions.forEach(userData => {
          const { productDisplayName, daysRemainingInTerm, key, productType, billingAccountKey ,status} = userData;
          const userSubscriptionDataObj = {
            productDisplayName,
            daysRemainingInTerm,
            key,
            productType,
            billingAccountKey,
            subscriptionExpired: false,
            subscriptionExpiring: false,
            subscriptionExpiringToday: false
          };
          if (productType === 'TRIAL') {
            if (daysRemainingInTerm <= MIN_DAYS_FOR_NOTIFICATION && daysRemainingInTerm >= 1) {
              // about to expire in future
              userSubscriptionDataObj.subscriptionExpiring = true;
              dispatch(meUpateNotificationsCount('increament'));  
            } else if (daysRemainingInTerm < 1 && status === 'ACTIVE') {
              // about to expire today
              userSubscriptionDataObj.subscriptionExpiringToday = true;
              dispatch(meUpateNotificationsCount('increament'));
            } else if (status === 'EXPIRED') {
              // has already expired
              userSubscriptionDataObj.subscriptionExpired = true;
              dispatch(meUpateNotificationsCount('increament')); 
            }
            dispatch(showUnreadLogo(true));
          }
          userSubscriptionData = [...userSubscriptionData, userSubscriptionDataObj];
        });
        // update the userData with the our final custom subscription data
        dispatch(meUpdateNotificationsData(userSubscriptionData,'subscription'));
      };
    }).catch((error) => {
      dispatch(showErrorBanner(true));
      console.error(`error subscription ${error}`);
    });
  },[dispatch]);

  useEffect(() => {
    if(meIsBillingAdmin){
      handleSubscriptionCall(billingMeAccounts);
      handlePaymentCall(billingMeAccounts);
    }
  },[billingMeAccounts,meIsBillingAdmin ,handleSubscriptionCall,handlePaymentCall]);

  return (
    <HomeView 
      isProductFound={isProductFound}
      entitlementsNotSupported={entitlementsNotSupported}
      meIsLoading={meIsLoading}
      meLoader={meLoader}
      meProducts={meProducts}
      meData={meData}
      meIsAccountAdmin={meIsAccountAdmin}
      meIsBillingAdmin={meIsBillingAdmin}
    />
  );
};

export const getValuesFromStore = (state) => {
  return {
    billingMeData: billingMeData(state),
    billingMeAccounts: billingMeAccounts(state),
    meData: meData(state),
    meNotificationsCount: meNotificationsCount(state),
    meEmail: meEmail(state),
    meIsLoading: meIsLoading(state),  
    meLoader: meLoader(state),
    meProducts : meProducts(state),
    meIsAccountAdmin: meIsAccountAdmin(state),
    meIsBillingAdmin: meIsBillingAdmin(state),
    notificationsData: meNotificationsData(state),
    isProductFound : isProductFound(state),
    entitlementsNotSupported : entitlementsNotSupported(state),
  };
};
export default HomeContainer;
