import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@getgo/chameleon-web-react-wrapper';
import { HelpDrawer } from './help-drawer';
import { Drawer } from './drawer';
import NotificationDrawerContainer from '../containers/notification-drawer-container';
import AvatarDrawer from './mobile-menu-view';
import { FormattedMessage } from 'react-intl';
import st from 'core/shared-translations';
import { Box } from '@mui/material';

const title = Object.freeze({
  'Notifications' :
  <Typography tag="p" variant="heading-small">
    <FormattedMessage {...st['shared.navbar.notificationsHeader']} />
  </Typography>,
  'Help' : 
  <Typography tag="p" variant="heading-small">
    <FormattedMessage {...st['shared.navbar.helpHeader']} />
  </Typography>,
  'MobileAvatarView': '',
});

const componentsMap = {
  Notifications: <NotificationDrawerContainer />,
  Help: <HelpDrawer />,
  MobileAvatarView: <AvatarDrawer />,
};

export const SideDrawer =({meShowSidePanel,onCloseDrawer}) =>{
  const ComponentToRender = componentsMap[meShowSidePanel];
  return (
    <Drawer
      title={title[meShowSidePanel]}
      open={!!meShowSidePanel}
      onCloseDrawer={onCloseDrawer}
      bannerMargin={false}
    >
      <Suspense fallback={<Box />}>
        { ComponentToRender || null }
      </Suspense>
    </Drawer>
  );
};
SideDrawer.propTypes ={
 meShowSidePanel: PropTypes.string.isRequired,
 onCloseDrawer: PropTypes.func.isRequired
};
  
export default SideDrawer;
  