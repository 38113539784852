import React, { Component, useEffect } from "react";
import PropTypes from "prop-types";
import { Provider } from "react-redux";
import history from "lib/history";
import Session from "lib/session";
import IntlContainer from "./intl-container";
import { ThemeProvider } from '@getgo/chameleon-web-react-wrapper';
import '@getgo/chameleon-web/components/registerAll';
import { Box } from "@mui/material";

const AppContainer = ({ store, locale }) => {
  /**
   * If there is a destination in storage then clear it out and route to it.
   * Does not use resolve-route because we are just handling user intent here
   */
  useEffect(() => {
    const target = Session.getOriginalTarget();
    if (target) {
      Session.removeOriginalTarget();
      history.push(target);
    }
  }, []);

  return (
    <ThemeProvider>
      <Provider store={store}>
        <Box style={{ height: "100%" }}>
          <IntlContainer meLocale={locale} />
        </Box>
      </Provider>
    </ThemeProvider>
  );
};
AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  locale: PropTypes.string.isRequired,
};

export default AppContainer;
