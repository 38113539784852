import { defineMessages } from 'react-intl';

const t = defineMessages({
  'shared.navbar.my-account': {
    id: 'shared.navbar.my-account',
    defaultMessage: 'My Account',
  },
  'shared.personal.info': {
    id: 'shared.personal.info',
    defaultMessage: 'Personal info',
    description: 'Section title for the personal info fields and navbar link',
  },
  'shared.navbar.signinsecurity': {
    id: 'shared.navbar.signinsecurity',
    defaultMessage: 'Sign In & security',
    description: 'The nav bar Sign In & Security link',
  },
  'shared.navbar.developerTools': {
    id: 'shared.navbar.developerTools',
    defaultMessage: 'Developer tools',
    description: 'The nav bar Developer Tools link',
  },
  'shared.navbar.products': {
    id: 'shared.navbar.products',
    defaultMessage: 'Products',
    description: 'The nav bar Products link',
  },
  'shared.navbar.signOut': {
    id: 'shared.navbar.signOut',
    defaultMessage: 'Sign Out',
    description: 'The nav bar Sign out link',
  },
  'shared.navbar.aboutUs': {
    id: 'shared.navbar.aboutUs',
    defaultMessage: 'About us',
    description: 'The nav bar About us link',
  },
  'shared.navbar.termsOfService': {
    id: 'shared.navbar.termsOfService',
    defaultMessage: 'Terms of service',
    description: 'The nav bar Terms of service link',
  },
  'shared.navbar.privacyPolicy': {
    id: 'shared.navbar.privacyPolicy',
    defaultMessage: 'Privacy policy',
    description: 'The nav bar Privacy policy link',
  },
  'shared.help.get-help': {
    id: 'shared.help.get-help',
    defaultMessage: 'Get help',
    description: 'The help Drawer Get Help link',
  },
  'shared.help.support': {
    id: 'shared.help.support',
    defaultMessage: 'Support',
    description: 'The Help Drawer support link',
  },
  'shared.navbar.copyRight': {
    id: 'shared.navbar.copyRight',
    defaultMessage: '© 2024 GoTo Group, Inc. All rights reserved.',
    description: 'The nav bar footer link',
  },
  'shared.navbar.notificationsHeader': {
    id: 'shared.navbar.notificationsHeader',
    defaultMessage: 'Notifications',
    description: 'The nav bar Notifications Header',
  },
  'shared.navbar.helpHeader': {
    id: 'shared.navbar.helpHeader',
    defaultMessage: 'Help',
    description: 'The nav bar Help Header',
  },
  'shared.creditCardExpiring': {
    id: 'shared.creditCardExpiring',
    defaultMessage: 'Credit card expiring',
    description: 'The credit card expiring Header',
  },
  'shared.navbar.theme': {
    id: 'shared.navbar.theme',
    defaultMessage: 'Theme',
    description: 'The Theme Header',
  },
  'shared.navbar.themeDark': {
    id: 'shared.navbar.themeDark',
    defaultMessage: 'Dark',
    description: 'The Dark Theme Header',
  },
  'shared.navbar.themeLight': {
    id: 'shared.navbar.themeLight',
    defaultMessage: 'Light',
    description: 'The Light Theme Header',
  },
  'shared.navbar.themeOs': {
    id: 'shared.navbar.themeOs',
    defaultMessage: 'Auto (syncs with OS settings)',
    description: 'The Os Theme Header',
  },
  "shared.trialExpiring-Header": {
    id: "shared.trialExpiring-Header",
    defaultMessage:"{productDisplayName} TRIAL expires in {daysRemainingInTerm, plural, one {# day} other {# days}}",
  },
  "shared.trialExpiringToday-Header": {
    id: "shared.trialExpiringToday-Header",
    defaultMessage:"{productDisplayName} TRIAL expires TODAY",
  },
  "shared.trialExpired-Header": {
    id: "shared.trialExpired-Header",
    defaultMessage:"{productDisplayName} TRIAL HAS EXPIRED",
  },
  "shared.announcement.RescueAssist.link":{
    id: "shared.announcement.RescueAssist.link",
   defaultMessage:"Learn more here",
  },
  "shared.announcement.RescueAssist.header": {
    id: "shared.announcement.RescueAssist.header",
    defaultMessage: "Looking for GoToAssist Remote Support? It’s now RescueAssist!",
  },
  "shared.announcement.GoToMeeting.header": {
    id: "shared.announcement.GoToMeeting.header",
    defaultMessage: "Buy now to continue using {productDisplayName}.",
  },
  "shared.announcement.GoToMeeting.link": {
    id:'shared.announcement.GoToMeeting.link',
    defaultMessage:"Buy Now",
  },
  'shared.signin': {
    id: 'shared.signin',
    defaultMessage: 'Sign-in',
  },
});
export default t;
