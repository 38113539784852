import React, { useRef, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { meLocale } from "modules/me-module";
import App from "core/components/app-view";
import Core from "core/containers/core-container";
import { IntlProvider } from "react-intl";
import { BrowserRouter } from "react-router-dom";
import appconfig from "config/appconfig";

const locales = {
  en_US: {
    messages: () => import("locales/en_US"),
    intlPolyfill: () => import("intl/locale-data/jsonp/en"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/en"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/en"),
  },
  de_DE: {
    messages: () => import("locales/de_DE"),
    intlPolyfill: () => import("intl/locale-data/jsonp/de"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/de"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/de"),
  },
  es_ES: {
    messages: () => import("locales/es_ES"),
    intlPolyfill: () => import("intl/locale-data/jsonp/es"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/es"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/es"),
  },
  fr_FR: {
    messages: () => import("locales/fr_FR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/fr"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/fr"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/fr"),
  },
  it_IT: {
    messages: () => import("locales/it_IT"),
    intlPolyfill: () => import("intl/locale-data/jsonp/it"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/it"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/it"),
  },
  zh_CN: {
    messages: () => import("locales/zh_CN"),
    intlPolyfill: () => import("intl/locale-data/jsonp/zh"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/zh"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/zh"),
  },
  ja_JP: {
    messages: () => import("locales/ja_JP"),
    intlPolyfill: () => import("intl/locale-data/jsonp/ja"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/ja"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/ja"),
  },
  ko_KR: {
    messages: () => import("locales/ko_KR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/ko"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/ko"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/ko"),
  },
  pt_BR: {
    messages: () => import("locales/pt_BR"),
    intlPolyfill: () => import("intl/locale-data/jsonp/pt"),
    intlPluralRules: () =>
      import("@formatjs/intl-pluralrules/dist/locale-data/pt"),
    intlRelativeTimeFormat: () =>
      import("@formatjs/intl-relativetimeformat/dist/locale-data/pt"),
  },
};

export const IntlContainer = () => {
  let { language } = useSelector(getLanguageFromStore);

  const [allValues, setAllValues] = useState({
    messages: {},
    locale: "en",
    loadingLocaleData: "initial",
  });

  const setLocaleValues = (locale) => {
    setAllValues((prevState) => ({
      ...prevState,
      loadingLocaleData: "true",
    }));
    const tmpLocale =
      appconfig.supportedLocales.indexOf(locale) > -1 ? locale : "en_US";
    const settings = locales[tmpLocale];
    if (!window.Intl) {
      settings.intlPolyfill();
      if (!window.Intl.PluralRules) {
        import("@formatjs/intl-pluralrules/polyfill");
      }
      if (!window.Intl.RelativeTimeFormat) {
        import("@formatjs/intl-relativetimeformat/polyfill");
      }
    }
    const promises = [
      settings.intlPluralRules(),
      settings.intlRelativeTimeFormat(),
      settings.messages(),
    ];
    Promise.all(promises).then((module) => {
      setAllValues({
        locale: tmpLocale.split("_")[0],
        messages: module[2].default,
        loadingLocaleData: "false",
      });
    });
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      setLocaleValues(language);
      mounted.current = true;
    } else {
      // if (prevProps.language !== language) {
      setLocaleValues(language);
      // }
    }
  }, [language]);
  if (allValues.loadingLocaleData === "false") {
    return (
      <IntlProvider
        locale={allValues.locale}
        textComponent="span"
        messages={allValues.messages}
      >
        <App>
          <BrowserRouter>
            <Core />
          </BrowserRouter>
        </App>
      </IntlProvider>
    );
  } else {
    return <span />;
  }
};
export const getLanguageFromStore = (state) => {
  let locale = meLocale(state);
  return { language: locale };
};

export default IntlContainer;
