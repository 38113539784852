// ------------------------------------
// Constants
// ------------------------------------

// Authentication Alerts
export const SHOW_ANNOUNCEMENT = 'SHOW_ANNOUNCEMENT';
export const SHOW_SIDE_PANEL = 'SHOW_SIDE_PANEL';
export const SHOW_UNREAD_LOGO = 'SHOW_UNREAD_LOGO';
export const SHOW_LOADER = 'SHOW_LOADER';
export const SHOW_ERROR_BANNER = 'SHOW_ERROR_BANNER';
export const SHOW_GOTOAPP_BANNER = 'SHOW_GOTOAPP_BANNER';
export const GOTO_THEME='GOTO_THEME';

export const UI_ALERT_AUTHENTICATION_WARNING_TOGGLE =
  "UI_ALERT_AUTHENTICATION_WARNING_TOGGLE";
export const UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE =
  "UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE";

export const UI_MESSAGE_BANNER_TOGGLE = "UI_MESSAGE_BANNER_TOGGLE";
export const UI_SHOW_MESSAGE_BANNER = "UI_SHOW_MESSAGE_BANNER";



// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  authentication: {
    warning: false,
    expired: false,
  },
  bannerMessage: {
    messageId: "",
    params: {},
    show: false,
  },
  showAnnouncement: false,
  showSidePanel: '',
  showUnreadLogo: false,
  loader: true,
  showErrorBanner: false,
  goToAppBanner: false,
  isLoading: true,
  theme: 'light',
};

// ------------------------------------
// Selectors
// ------------------------------------
// Tree Object selectors
export const uiTree = (state) => state.ui || {};
export const uiAuthentication = (state) => uiTree(state).authentication || {};

// authentication selectors
export const uiAlertAuthenticationWarningVisible = (state) =>
  uiAuthentication(state).warning;
export const uiAlertAuthenticationWarningTime = (state) =>
  uiAuthentication(state).warningTime;
export const uiAlertAuthenticationExpiredVisible = (state) =>
  uiAuthentication(state).expired;

// message banner selectors
export const uiMessageBannerVisible = (state) =>
  uiTree(state).bannerMessage.show;
export const uiMessageBannerId = (state) =>
  uiTree(state).bannerMessage.messageId;
export const uiMessageBannerParams = (state) =>
  uiTree(state).bannerMessage.params;

export const meShowAnnouncement = (state) => state.ui.showAnnouncement;

export const meShowSidePanel = (state) => state.ui.showSidePanel;

export const meShowUnreadLogo = (state) => state.ui.showUnreadLogo;

export const meLoader = (state) => state.ui.loader;

export const meShowErrorBanner = (state) => state.ui.showErrorBanner;

export const meGotoAppBanner = (state) => state.ui.goToAppBanner;

export const meIsLoading = (state) => state.ui.isLoading;

export const meTheme = (state) => state.ui.theme;
// ------------------------------------
// Actions
// ------------------------------------

export const uiAlertAuthenticationWarningToggle = (warningTime) => ({
  type: UI_ALERT_AUTHENTICATION_WARNING_TOGGLE,
  warningTime,
});

export const uiAlertAuthenticationExpiredToggle = () => ({
  type: UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE,
});

export const uiShowMessageBannerToggle = () => ({
  type: UI_MESSAGE_BANNER_TOGGLE,
});

export const uiShowMessageBanner =
  (messageId, params = {}) =>
  (dispatch) => {
    return dispatch({
      type: UI_SHOW_MESSAGE_BANNER,
      messageId,
      params,
    });
  };

  export const showAnnouncement = (value)=> {
    return {
      type: SHOW_ANNOUNCEMENT,
      payload: value
    };
  };

  export const showSidePanel = (value) => ({
    type: SHOW_SIDE_PANEL,
    payload: value,
  });

  export const showUnreadLogo =(value)=> {
    return {
      type: SHOW_UNREAD_LOGO,
      payload: value
    };
  };

  export const showLoader = (value) => ({
     type: SHOW_LOADER,
     payload: value
  });

  export const showErrorBanner = (value) => ({
    type: SHOW_ERROR_BANNER,
    payload: value
  });

  export const showGoToAppBanner= (value) => ({
     type: SHOW_GOTOAPP_BANNER,
     payload: value
  });

  export const setTheme = (value) => ({
    type: GOTO_THEME,
    payload: value,
  });

// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [UI_ALERT_AUTHENTICATION_WARNING_TOGGLE]: (state, action) => ({
    ...state,
    authentication: {
      ...state.authentication,
      warning: !state.authentication.warning,
      warningTime: action.warningTime,
    },
  }),
  [UI_ALERT_AUTHENTICATION_EXPIRED_TOGGLE]: (state) => ({
    ...state,
    authentication: {
      ...state.authentication,
      warning: false,
      expired: !state.authentication.expired,
    },
  }),
  [UI_MESSAGE_BANNER_TOGGLE]: (state) => ({
    ...state,
    bannerMessage: {
      ...state.bannerMessage,
      messageId: "",
      params: {},
      show: !state.bannerMessage.show,
    },
  }),
  [UI_SHOW_MESSAGE_BANNER]: (state, action) => ({
    ...state,
    bannerMessage: {
      ...state.bannerMessage,
      messageId: action.messageId,
      params: action.params,
      show: true,
    },
  }),
  [SHOW_ANNOUNCEMENT]: (state, action) => {
    if (typeof action.payload === 'boolean' ) {
        return {
        ...state,
        showBanner: action.payload,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },

  [SHOW_SIDE_PANEL]: (state, action) => {
    if (typeof action.payload === 'string' ) {
        return {
        ...state,
        showSidePanel: action.payload,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
  [SHOW_UNREAD_LOGO]: (state, action) => {
    if (typeof action.payload === 'boolean' ) {
        return {
        ...state,
        showUnreadLogo: action.payload,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
  [SHOW_LOADER]: (state, action) => {
    if (typeof action.payload === 'boolean' ) {
        return {
        ...state,
        loader: action.payload,
        };
    }
    return { ...state, isLoading: false };
  },
  [SHOW_ERROR_BANNER]: (state, action) => {
    if (typeof action.payload === 'boolean') {
        return {
        ...state,
        showErrorBanner: action.payload,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
  [SHOW_GOTOAPP_BANNER]: (state, action) => {
    if (typeof action.payload === 'boolean' ) {
        return {
        ...state,
        goToAppBanner: action.payload,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
  [GOTO_THEME]: (state, action) => {
    if (typeof action.payload === 'string' ) {
      let chameleonTheme;
      if(action.payload === 'os'){
        const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
        chameleonTheme = (prefersDarkMode ? 'dark' : 'light');
      }
      else{
        chameleonTheme = action.payload;
      }
        return {
        ...state,
        theme: chameleonTheme,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const uiReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default uiReducer;
