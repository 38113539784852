import {
  daoMeGet,
  daoAvatarUrlsGet,
} from 'dao/me-dao';
import appconfig from 'config/appconfig';
import { isFauxLocale } from 'lib/utility-functions';
import { prodArray } from 'lib/product-specs';
import { intersection , xor} from 'lodash';
// ------------------------------------
// Constants
// ------------------------------------
export const ME_REQUEST_GET = 'ME_REQUEST_GET';
export const ME_RECEIVE_GET_SUCCESS = 'ME_RECEIVE_GET_SUCCESS';
export const ME_RECEIVE_GET_FAILURE = 'ME_RECEIVE_GET_FAILURE';

export const ME_AVATAR_URLS_REQUEST_GET = 'ME_AVATAR_URLS_REQUEST_GET';
export const ME_AVATAR_URLS_RECEIVE_GET_SUCCESS = 'ME_AVATAR_URLS_RECEIVE_GET_SUCCESS';
export const ME_AVATAR_URLS_RECEIVE_GET_FAILURE = 'ME_AVATAR_URLS_RECEIVE_GET_FAILURE';

export const ME_UPDATE_NOTIFICATIONS_COUNT = 'ME_UPDATE_NOTIFICATIONS_COUNT';

export const ME_NOTIFICATIONS_DATA = "ME_NOTIFICATIONS_DATA";

// ------------------------------------
// Initial State
// ------------------------------------
export const initialState = {
  // put your module data under this object
  data: {
    displayName: '',
    locale: 'en_US',
    timezone: 'America/Los_Angeles',
  },
  // generic or global properties go here

  // Contains avatar image source specified by the user during the session.
  // This source serves as a temporary storage to improve user experience, since uploaded images take time to become available.
  newAvatarImageSource: '',

  forceSamlSsoLogin: false,

  isLoading: false,

  notificationsCount: 0,

  supportExperience: {
    handledByProduct: false,
    limited: false
  },

  notificationsData: {
    settingsData: [],
    cardData: [],
    adminData: [],
    subscriptionData: [],
  },
  manualEntitlements: [],

};

// ------------------------------------
// Selectors
// ------------------------------------
export const meIsLoading = (state) => state.me.isLoading;

export const meData = (state) => state.me.data;

export const meNewAvatarImageSource = (state) => state.me.newAvatarImageSource;

export const meNotificationsCount = (state) => state.me.notificationsCount || 0;

export const meNotificationsData = (state) => state.me.notificationsData;

export const meLocale = (state) => {
  const data = meData(state);
  return data && data.locale;
};

export const meId = (state) => {
  const data = meData(state);
  return data && data.id;
};

export const meUserName = (state) => {
  const data = meData(state);
  return data && data.userName;
};

export const meEmail = (state) =>{
  const data = meData(state);
  return data && data.emails.find(val => (val.primary === true)).value;
};

export const meDisplayName = (state) => {
  const data = meData(state);
  return data && data.displayName;
};

export const getGetGoSchema = (state) => {
  const data = meData(state);
  return data[appconfig.scimSchemas.getGo] || null;
};

export const meUnifiedAdminFlag = (state) => {
  const data = meData(state);
  return (data && data['urn:scim:schemas:extension:getgo:1.0'] && data['urn:scim:schemas:extension:getgo:1.0'].unifiedAdmin) || false;
};

export const meEntitlements = (state) => {
  const data = meData(state);
  return data && data.entitlements;
};

export const entitlementsNotSupported = (state) =>{
  const entitlements = meEntitlements(state) || [];
  return entitlements && entitlements.length > 0;
};

export const meShowBillingSection = (state) =>{
  const entitlements = meEntitlements(state) || [];
  return entitlements.indexOf('jivebilling') >= 0;
};

export const meShowAdminSection = (state) =>{
  const entitlements = meEntitlements(state) || [];
  return entitlements.indexOf('jiveadmin') >= 0;
};

export const meShowAddLicenseButton = (state) =>{
  const entitlements = meEntitlements(state) || [];
  return entitlements.filter(x => x !== 'g2m').filter(value => value !== 'acctadmin').length === 0;
};

export const meShowLaunchMDMLink = (state) =>{
  const entitlements = meEntitlements(state) || [];
  return entitlements.some(value => value === 'devicemanagement');
};

export const meProducts = (state) => {
  let entitlements = meEntitlements(state) || [];
    // we only show gotoconnect tile if jive and gotoconnect are both present in the entitlements
  if (entitlements.includes('jive') && entitlements.includes('gotoconnect')) {
    entitlements = entitlements.filter(value => value !== 'jive');
  }
  return entitlements.filter(entitlement => (
    (entitlement !== 'acctadmin') &&
    (entitlement !== 'orgadmin') 
  ));
};

export const meIsAccountAdmin = (state) => {
  const entitlements = meEntitlements(state) || [];
  return (entitlements && (entitlements.indexOf('acctadmin') > -1)) || false;
};

export const meSupportExperience = (state) => {
  const getGoSchema = getGetGoSchema(state);
  const supportExperienceFlag = getGoSchema && getGoSchema.supportExperience;
  if (supportExperienceFlag) {
    switch (supportExperienceFlag) {
      case 'JIVE_ONLY':
        state.me.supportExperience.handledByProduct = true;
        break;
      case 'HIDE':
        state.me.supportExperience.limited = true;
        break;
      default:
        break;
    }
    return state.me.supportExperience;
  }
  return null;
};

export const meIsConflicted = (state) => {
  const getGoSchema = getGetGoSchema(state);
  return !!(getGoSchema && getGoSchema.conflicted);
};

export const isProductFound = (state) => {
  const entitlements = meEntitlements(state) || [];
  if (entitlements && entitlements.length > 0) {
    const prodFound = intersection(prodArray, entitlements);
      return prodFound.length > 0;
  }
  return false; 
};

export const meHideBillingSection =(state) => {
  let userHasOnlyConnectBundle = false;
  let userHasOnlyGotoconnect = false;
  const supportExperience = meSupportExperience(state);
  const entitlements = meEntitlements(state) || [];
  const userOnlyJive = entitlements.every(value => value.includes('jive') || value.includes('acctadmin'));
  const connectBundleProducts = entitlements.filter(value => (value.includes('jive') || value.includes('g2m') || value.includes('acctadmin')));
  if (connectBundleProducts.indexOf('g2m') >= 0 && connectBundleProducts.indexOf('jive') >= 0) {
   // only g2m + jive
   userHasOnlyConnectBundle = (xor(connectBundleProducts, entitlements).length === 0);
  }

  const gotoconnectBundle = entitlements.filter( value => (value.includes('jive') || value.includes('gotoconnect') || value.includes('acctadmin')));
  if (gotoconnectBundle.indexOf('gotoconnect') >= 0) {
   userHasOnlyGotoconnect = (xor(gotoconnectBundle, entitlements).length === 0);
  }

  return userOnlyJive 
    || userHasOnlyConnectBundle 
    || userHasOnlyGotoconnect
    || (supportExperience && supportExperience.limited)
    || (supportExperience && supportExperience.handledByProduct);
};

export const meHideAdminSection =(state) =>{
  let userHasOnlyConnectBundle = false;
  const entitlements = meEntitlements(state) || [];
  const connectBundleProducts = entitlements.filter(value => (value.includes('jive') || value.includes('g2m') || value.includes('acctadmin')));
  if (connectBundleProducts.indexOf('g2m') >= 0 && connectBundleProducts.indexOf('jive') >= 0) {
    // only g2m + jive
    userHasOnlyConnectBundle = (xor(connectBundleProducts, entitlements).length === 0);
  }
  return ((entitlements.indexOf('jive') >= 0)
   || userHasOnlyConnectBundle
   || (entitlements.indexOf('gotoconnect') >= 0)) 
   && (entitlements.indexOf('acctadmin') === -1);
};

export const meShowEnhancedSecurity = (state) =>{
  const data = meData(state);
  return data && data['urn:scim:schemas:extension:getgo:1.0'].enhancedSecurity === undefined;
};

export const meShowGotoBanner =(state) => {
  const entitlements = meEntitlements(state) || [];
  return entitlements.includes('g2m') 
    && !entitlements.includes('jive') 
    && !entitlements.includes('gotoconnect');
};

export const meG2mProduct = (state) =>{
  const products = meProducts(state);
  return products && (products.indexOf("g2m") > -1 || products.indexOf("g2mfree") > -1);
};
// ------------------------------------
// Actions
// ------------------------------------
export const meReceiveGetSuccess = (payload = {}) => ({
  type: ME_RECEIVE_GET_SUCCESS,
  payload,
});

export const meReceiveGetFailure = (payload = {}) => ({
  type: ME_RECEIVE_GET_FAILURE,
  payload,
});

export const meRequestGet = () => dispatch => {
  dispatch({
    type: ME_REQUEST_GET,
  });

  return daoMeGet()
    .then(
      response => {
        dispatch(meReceiveGetSuccess(response.data));
      },
      ex => dispatch(meReceiveGetFailure(ex)),
    );
};

export const meAvatarUrlsReceiveGetSuccess = (payload = {}) => ({
  type: ME_AVATAR_URLS_RECEIVE_GET_SUCCESS,
  payload,
});

export const meAvatarUrlsReceiveGetFailure = (payload = {}) => ({
  type: ME_AVATAR_URLS_RECEIVE_GET_FAILURE,
  payload,
});

export const meAvatarUrlsRequestGet = () => dispatch => {
  dispatch({
    type: ME_AVATAR_URLS_REQUEST_GET,
  });

  return daoAvatarUrlsGet()
    .then(
      response => dispatch(meAvatarUrlsReceiveGetSuccess(response.data)),
      ex => dispatch(meAvatarUrlsReceiveGetFailure(ex)),
    );
};

export const meUpateNotificationsCount= (payload={}) => ({
    type:  ME_UPDATE_NOTIFICATIONS_COUNT,
    payload,
});

export const meUpdateNotificationsData= (payload={},dataType) => ({
    type: ME_NOTIFICATIONS_DATA,
    payload,
    dataType,
  });
// ------------------------------------
// Action Handlers
// ------------------------------------
const ACTION_HANDLERS = {
  [ME_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),

  [ME_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      let language = '';
      const payload = action.payload;
      const locale = (payload && payload.locale) || '';
      const foundLocale = locale && appconfig.supportedLocales.includes(locale);
      if (foundLocale) {
        payload.locale = locale;
      } else if (locale.length >= 2) { // some products use language designation only (e.g., "fr"), so match the closest available locale
        language = locale.substring(0, 2).toLowerCase();
        const substituteLocale = appconfig.supportedLocales.find(supportedLocale => supportedLocale.toLowerCase().indexOf(language) === 0);
        payload.locale = substituteLocale || 'en_US';
      } else {
        payload.locale = 'en_US';
      }
      payload.selectedLocale = payload.locale;
      if (locale.toLowerCase() === 'en_gb') {
        payload.selectedLocale = 'en_GB';
      } else if (language.length === 2 && isFauxLocale(language)) {
        payload.selectedLocale = language;
      }
      if (!payload.timezone) {
        payload.timezone = 'America/Los_Angeles';
      }
      return { ...state, data: payload, isLoading: false };
    }
    return { ...state, isLoading: false };
  },

  // @TODO (PMP-14) determine what to do when GET ME fails in UX (retry, some simple error message, redirect?)
  [ME_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_AVATAR_URLS_REQUEST_GET]: (state) => ({ ...state, isLoading: true }),

  [ME_AVATAR_URLS_RECEIVE_GET_SUCCESS]: (state, action) => {
    if (typeof action.payload === 'object') {
      return {
        ...state,
        avatarLocationUrls: { ...action.payload },
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_AVATAR_URLS_RECEIVE_GET_FAILURE]: (state) => ({
    ...state,
    isLoading: false,
  }),

  [ME_UPDATE_NOTIFICATIONS_COUNT]: (state, action) => {
   
    if (action.payload === 'increament') {
      return {
        ...state, 
        notificationsCount: state.notificationsCount+1,
        isLoading: false,
      };
    }
    else if(action.payload === 'clear'){
      return {
        ...state,
        notificationsCount: 0,
        isLoading: false,
      };
    }
    return { ...state, isLoading: false };
  },

  [ME_NOTIFICATIONS_DATA]: (state, action) => {
    switch(action.dataType){
      
      case 'settings': state.notificationsData.settingsData.push(action.payload);break;
      case 'card': state.notificationsData.cardData = action.payload;break;
      case 'admin' : state.notificationsData.adminData = action.payload;break;
      case 'subscription' : state.notificationsData.subscriptionData = action.payload;break;
      case 'empty' : state.notificationsData = action.payload; break;
      default: null;
    }
    if (typeof action.payload === 'object') {
      
      return {
        ...state,
        notificationsData: state.notificationsData,
        isLoading: false,
        };
    }
    return { ...state, isLoading: false };
  },
};

// ------------------------------------
// Reducer
// ------------------------------------
const meReducer = (state = initialState, action) => {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
};

export default meReducer;
