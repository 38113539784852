import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import {
  defineMessages,
  FormattedMessage,
} from "react-intl";
import appconfig from "config/appconfig";
import classes from "components/alert.scss";
import { InfoFilledIcon } from '@getgo/chameleon-icons/react';
import { Button ,Typography } from "@getgo/chameleon-web-react-wrapper";

const messagesConfig = {
  userIsConflicted: {
    messageType: appconfig.alertTypes.INFO,
    showIcon: true,
    iconClass: <InfoFilledIcon />,
    bodyResourceId: "messages.userIsConflicted.body",
    showButton: true,
    buttonTextId: "messages.userIsConflicted.button",
  },
};

const t = defineMessages({
  "messages.userIsConflicted.body": {
    id: "messages.userIsConflicted.body",
    defaultMessage:
      "Profile settings are currently locked. Please sign out and follow the login flow to update your account.",
  },
  "messages.userIsConflicted.button": {
    id: "messages.userIsConflicted.button",
    defaultMessage: "Sign out",
  },
});

const MessageBanner = ({ handleAlertDismiss, messageId, params }) => (
  <Alert
    type={messagesConfig[messageId].messageType || appconfig.alertTypes.INFO}
    icon={messagesConfig[messageId].iconClass}
    body={
      <Typography>
        <span className={classes.bannerMessageText}>
          <FormattedMessage
            {...t[messagesConfig[messageId].bodyResourceId]}
            values={params}
          />
        </span>
      </Typography>
    }
    buttons={
      messagesConfig[messageId].showButton && (
        <Button
          id="banner-button"
          size="small"
          variant="neutral"
          className={classes.bannerMessageCTA}
          onClick={params.onclick}
        >
          <FormattedMessage {...t[messagesConfig[messageId].buttonTextId]} />
        </Button>
      )
    }
    handleDismiss={params.onDismiss ? params.onDismiss : handleAlertDismiss}
    isCloseButton={params.isCloseButton}
  />
);


MessageBanner.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  messageId: PropTypes.string.isRequired,
  params: PropTypes.object,
};

export default MessageBanner;
