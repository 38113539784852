import React  from "react";
import PropTypes from "prop-types";
import classes from "./alert.scss";
import appconfig from "config/appconfig";
import { Alert as ChameleonAlert } from "@getgo/chameleon-web-react-wrapper";
import { Box } from "@mui/material";

export const Alert = ({ type, icon, body, buttons, handleDismiss}) => (
   <Box>
     <ChameleonAlert 
      variant={type} 
      closable 
      onClose={handleDismiss}>
      <Box className={classes.alertContainer}>
        <Box className={classes.content}>
          {icon}
          &nbsp;
          {body}
          &nbsp;
          {buttons}
        </Box>
      </Box>
     </ChameleonAlert>
   </Box>
);

Alert.defaultProps = {
  type: appconfig.alertTypes.WARNING,
  handleDismiss: () => { },
};

Alert.propTypes = {
  type: PropTypes.oneOf(["warning", "danger", "success", "info"]),
  icon: PropTypes.any,
  body: PropTypes.any.isRequired,
  buttons: PropTypes.any,
  handleDismiss: PropTypes.func,
};

export default Alert;
