export const cardSpecs = {
    DISCOVER: 'discover',
    AMEX: 'american-express',
    MASTERCARD: 'mastercard',
    VISA: 'visa'
};

export const  months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
    