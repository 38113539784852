import React ,{ Fragment }from "react";
import {
  NavigationRail,
  NavigationRailItem,
  NavigationRailLink
} from "@getgo/chameleon-web-react-wrapper";
import {
  PersonOutlinedIcon,
  ProtectedOutlinedIcon ,
  CodeOutlinedIcon,
  AppSwitcherOutlinedIcon
} from "@getgo/chameleon-icons/react";
import classes from "./main-nav-view.scss";
import environment from 'lib/environment';
import { FormattedMessage } from "react-intl";
import st from 'core/shared-translations';

const profile_info = environment.get().profile.info;
const sign_in_security = environment.get().profile.login;
const dev_tools = environment.get().profile.devTools;
const products = environment.get().api.redirect_url;


export const SideNav = () => {
  return (
    <Fragment>
      <NavigationRail
        collapse-label="Collapse"
        aria-label="side-nav"
        className={classes["sideNavView"]}
      >
        <NavigationRailItem
          icon={<AppSwitcherOutlinedIcon />}
          href={products}
          selected
        >
          <FormattedMessage {...st['shared.navbar.products']} />
        </NavigationRailItem>
        <NavigationRailLink
          href={profile_info}
          target="_self"
          icon={<PersonOutlinedIcon />}
        >
          <FormattedMessage {...st['shared.personal.info']} />
        </NavigationRailLink>
        <NavigationRailLink
          href={sign_in_security}
          target="_self"
          icon={<ProtectedOutlinedIcon />}
        >
          <FormattedMessage {...st['shared.navbar.signinsecurity']} />
        </NavigationRailLink>
        <NavigationRailLink
          href={dev_tools}
          target="_self"
          icon={<CodeOutlinedIcon />}
        >
          <FormattedMessage {...st['shared.navbar.developerTools']} />
        </NavigationRailLink>
      </NavigationRail>
    </Fragment>
  );
};

export default SideNav;
