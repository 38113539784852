import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { meShowAnnouncement ,showAnnouncement} from "modules/ui-module";
import { prodSpecs } from "lib/product-specs";
import { Banner ,Button} from "@getgo/chameleon-web-react-wrapper";
import { FormattedMessage } from "react-intl";
import { meEntitlements,meSupportExperience, meNotificationsData} from "modules/me-module";
import { meIsBillingAdmin } from "modules/billing-module";
import environment from "lib/environment";
import st from 'core/shared-translations';
import { convertToUTC } from "lib/utility-functions";

export const Announcement = () => {
  const {
    meEntitlements,
    meShowAnnouncement,
    supportExperience,
    meIsBillingAdmin,
    notificationsData
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();
  const [announcement,setAnnouncement] = useState(false);

  useEffect(()=>{
    // only show the announcement module if localStorage is available */
    if (typeof (Storage) !== 'undefined') {
      // show the announcement on load if active
      if (localStorage.getItem('showAnnouncement') === 'true' || !localStorage.getItem('showAnnouncement')) {
        dispatch(showAnnouncement(true));
        setAnnouncement(true);
      }
    }

  },[dispatch]);

  const handleActiveAnnouncement=(product)=> {
    if (product.startDate && product.endDate) {
      const startDate = convertToUTC(product.startDate); 
      const endDate = convertToUTC(product.endDate);
      const today = new Date();
      const announcementActive = ((today >= startDate) && (today <= endDate) && ((localStorage.getItem('showAnnouncement') === 'true') || !localStorage.getItem('showAnnouncement')));
      return announcementActive;
    }
    return true;
  };

  const disableAnnouncement=()=> {
    dispatch(showAnnouncement(false));
    setAnnouncement(true);
    localStorage.setItem('showAnnouncement', false);
  };

  //goto meeting has announcement set to true till 2050   
  const renderAnnouncement= (prod) =>{
  if (prodSpecs[prod] && prodSpecs[prod].announcement) {
    // render the product specific announcement if active
    if (handleActiveAnnouncement(prodSpecs[prod])) {
      // update the showAnnouncement flag
      localStorage.setItem('showAnnouncement', true);

      const prodName = prodSpecs[prod].product;
      let announcementText = `shared.announcment.${prodName}.header`;
      const announcementLink = `shared.announcement.${prodName}.link`;
      // we only show announcement for g2m if the user has only one product account
      const onlyG2mInProductList = ['g2m'];
      // a single product can have either only product name in the array or the product and acctadmin if the user is an account
      const onlyG2mProductAccount = (meIsBillingAdmin && prodName === 'GoToMeeting' && meEntitlements.filter(x => !onlyG2mInProductList.includes(x)).filter(value => value !== 'acctadmin').length === 0);
      let daysRemaining;
      // if the subscription is not expired or expiring in 7 days then we don't show the banner
      let showAddLicenseBanner = false;
      const MIN_DAYS_FOR_BANNER = 7;
      if (onlyG2mProductAccount) {
        // subscriptionData object is only available in this.props.userData if the user account is a trial account
        for (const data in notificationsData.subscriptionData) {
          // we can only show one banner per product so once the banner is up for one of the subscriptions then break the loop
          if (showAddLicenseBanner) {
            break;
          }
          const subscription = notificationsData.subscriptionData[data];
          // we only show the banner if the trial subscription is expiring with in 7 days or has already expired
          if (subscription.subscriptionExpiring === true && subscription.daysRemainingInTerm <= MIN_DAYS_FOR_BANNER) {
            announcementText = 'shared.trialExpiring-Header';
            daysRemaining = subscription.daysRemainingInTerm;
            showAddLicenseBanner = true;
          } else if (subscription.subscriptionExpiringToday === true) {
            announcementText = 'shared.trialExpiringToday-Header';
            showAddLicenseBanner = true;
          } else if (subscription.subscriptionExpired === true) {
            announcementText = 'shared.trialExpired-Header';
            showAddLicenseBanner = true;
          }
        }
      }
      if ( showAddLicenseBanner || prodName !== 'GoToMeeting') {
        // show g2m announcement if add license button condition is met or show the announcement for other products
        return (
          <Banner
            variant="neutral"
            action={
              <Button
                variant="inverted" 
                className="download-gotoapp-button" 
                data-test="announcement-button"
                onClick={() => { disableAnnouncement(); window.open(environment.get().announcement[prodName], '_blank'); }}>         
                <FormattedMessage {...st[announcementLink]} />      
              </Button>
            }
            onClose={() => {disableAnnouncement(); }}
            closable
          >
            <FormattedMessage {...st[announcementText]} values={{ productDisplayName: prodName, daysRemainingInTerm: daysRemaining }} />
          </Banner>
        );
      }
      return null;
    }
    return null;
  }
  return null;
  };
  
  if ((announcement|| meShowAnnouncement)  && !(supportExperience && (supportExperience.handledByProduct || supportExperience.limited))) {
    return(
      meEntitlements ? meEntitlements.map(prod => renderAnnouncement(prod)) : null
    );
  };
  return null;
};

export const getValuesFromStore = (state) => {
  return {
    meShowAnnouncement: meShowAnnouncement(state),
    meIsBillingAdmin: meIsBillingAdmin(state),
    supportExperience: meSupportExperience(state),
    meEntitlements: meEntitlements(state),
    notificationsData : meNotificationsData(state),
  };
};

export default Announcement;
