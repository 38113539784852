import React from 'react';
import PropTypes from 'prop-types';
import { CloseOutlinedIcon } from '@getgo/chameleon-icons/react';
import { IconButton, TooltipV3, Typography } from '@getgo/chameleon-web-react-wrapper';
import {
  Box, Drawer as MuiDrawer, Grid,
} from '@mui/material';
import { FormattedMessage ,defineMessages } from 'react-intl';
import classes from './drawer.scss';

const t = defineMessages({
  'drawer.close': {
    id: 'drawer.close',
    defaultMessage: 'Close',
  },
});

export const Drawer = ({
  open, 
  onCloseDrawer, 
  title, 
  children,  
  }) => {
    
  const isMobileView = (window.innerWidth <= '768');

  return (  
    <Box
      id="side-drawer"
      className={`Drawer ${classes['drawer']}`}
    >
      <MuiDrawer
        classes={{
          paper: classes['drawerPaper'],
          paperAnchorDockedRight: classes['drawerPaperAnchorDockedRight']
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        {title && (
          <Box className={classes['drawerHeader']}>
            <Grid container justifyContent="space-between" spacing={2}>
              <Grid item>
                <Box display="flex">
                  <Typography data-testid="drawerTitle" variant="heading-small">
                    {title}
                  </Typography>
                </Box>
              </Grid>
              {!isMobileView && <Grid item>
                <IconButton 
                  id="close-drawer-icon" 
                  labelId="close-drawer-icon" 
                  size="small" 
                  className={classes['closeButton']} 
                  onClick={onCloseDrawer} 
                  data-testid="drawer-close-btn">
                  <CloseOutlinedIcon size="24px" />
                </IconButton>
                <TooltipV3 triggerId="close-drawer-icon">
                  <span><FormattedMessage {...t['drawer.close']} /></span>
                </TooltipV3>
              </Grid>}
            </Grid>
          </Box>
        )}
        {children}
      </MuiDrawer>
    </Box>
  );
};

Drawer.propTypes = {
  onCloseDrawer: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.node,
  children: PropTypes.node
};
export default Drawer;
