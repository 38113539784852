import {
  MeetingStaticPictogram, WebinarStaticPictogram, PresentationStaticPictogram, PhoneConversationStaticPictogram
} from '@getgo/chameleon-pictograms/react';
import {
  GtrBlackYellowPurpleVerticalIcon ,
  GtrWhiteYellowVerticalIcon,
  GotoPartnerBlackYellowIcon,
  GotoPartnerWhiteYellowIcon,
  GrasshopperMarkIcon
} from '@getgo/chameleon-icons/react';
import React from 'react';
import OpenVoice from 'static/images/svg/openvoice.svg';
import Jive from 'static/images/svg/jive-logo.svg';
import GotoAssist from 'static/images/svg/g2alogo.svg';
import GotoAssistSeeit from 'static/images/svg/seeit-logo.svg';
import gotoMyPC from 'static/images/svg/gotomypc.svg';

const productFamilies = {
  meeting: 'g2m',
  webinar: 'g2w',
  training: 'g2t',
  gotoassist: 'g2a',
  gotoassistServiceDesk: 'g2asd',
  gotoassistRemoteSupport: 'g2ars',
  gotoassistseeit: 'g2aseeit',
  openVoice: 'openvoice',
  grassHopper: 'grasshopper',
  gotoMyPC: 'g2p',
  connect: 'gotoconnect',
  jive: 'jive',
  gotoResolve: 'gotoresolve',
  gotoApplicationCards: 'partner'
};

export const GetProductFamilyIcon = ({
  productFamily,
  theme
}) => {
  let productFamilyIcon = null;

  switch (productFamily) {
      case productFamilies.connect:
        productFamilyIcon = <PhoneConversationStaticPictogram size="60px" />;
        break;
      case productFamilies.meeting:
        productFamilyIcon = <MeetingStaticPictogram size="80px" />;
        break;
      case productFamilies.webinar:
        productFamilyIcon = <WebinarStaticPictogram size="80px" />;
        break;
      case productFamilies.training:
          productFamilyIcon = <PresentationStaticPictogram size="80px" />;
          break;
      case productFamilies.gotoResolve:
        productFamilyIcon = (theme === 'light') ? <GtrBlackYellowPurpleVerticalIcon size="80px" /> : <GtrWhiteYellowVerticalIcon size="80px" />;
        break;
      case productFamilies.openVoice:
        productFamilyIcon = <img alt="openvoice-logo" style={{ padding: '15px' }} src={OpenVoice} />;
        break;
      case productFamilies.jive:
          productFamilyIcon = <img alt="jive-logo" style={{ padding: '15px', height: '50px', width: '50px' }} src={Jive} />;
          break;
      case productFamilies.gotoassistServiceDesk:
      case productFamilies.gotoassistRemoteSupport:
      case productFamilies.gotoassist:
        productFamilyIcon = <img alt="assit-logo" style={{ padding: '15px', height: '50px', width: '50px' }} src={GotoAssist} />;
        break;
      case productFamilies.gotoassistseeit:
        productFamilyIcon = <img alt="assistSeeit-logo" style={{ padding: '20px' }} src={GotoAssistSeeit} />;
        break;
      case productFamilies.grassHopper:
        productFamilyIcon = <GrasshopperMarkIcon size="80px" />;
        break;
      case productFamilies.gotoMyPC:
        productFamilyIcon = <img alt="gotoMyPC-logo" style={{ padding: '20px', height: '50px', width: '50px' }} src={gotoMyPC} />;
        break;
      case productFamilies.gotoApplicationCards:
        productFamilyIcon = (theme === 'light') ? <GotoPartnerBlackYellowIcon size="100px" /> : <GotoPartnerWhiteYellowIcon size="100px" />;
        break;
      default:
          productFamilyIcon;
  }
  return (
    productFamilyIcon
  );
};

export default GetProductFamilyIcon;
