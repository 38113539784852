import React, { useCallback, useEffect,useState }  from "react";
import { useDispatch ,useSelector} from "react-redux";
import {
  uiAlertAuthenticationWarningTime,
  uiAlertAuthenticationWarningToggle,
} from "modules/ui-module";
import AlertAuthWarning from "components/alert-auth-warning-view";
import Session from "lib/session";
import config from "config/appconfig";
import { Box } from "@mui/material";

export const AlertAuthWarningContainer = () => {

  const { timeInMs } = useSelector(getValuesFromStore);
  const [timeInSec,setTimeInSec]  = useState(Math.round(timeInMs / 1000));
  const dispatch = useDispatch();

  const handleAlertDismiss = () =>{
    dispatch(uiAlertAuthenticationWarningToggle());
  };
  
  const handleReload = () =>{
    Session.removeItem(config.storage.oauth);
    return window.location.reload();
  };

  useEffect(()=>{
    
    if (timeInSec < 0) return;
    const countdown = setInterval(() => {
      setTimeInSec((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(countdown);

  },[timeInSec]);

  return (
    <Box>
      <AlertAuthWarning
        handleAlertDismiss={handleAlertDismiss}
        handleReload={handleReload}
        timeLeft={timeInSec}
      />
    </Box>
  );
};

export const getValuesFromStore = (state) => {
  return {
    timeInMs: uiAlertAuthenticationWarningTime(state),
  };
};

export default AlertAuthWarningContainer;


