import React from "react";
import PropTypes from "prop-types";
import Alert from "components/alert-view";
import { FormattedMessage, defineMessages } from "react-intl";
import classes from "./alert.scss";
import appconfig from "config/appconfig";
import { Button, Typography } from "@getgo/chameleon-web-react-wrapper";
import st from "core/shared-translations";
import { AlertOutlinedIcon } from '@getgo/chameleon-icons/react';

const t = defineMessages({
  "alert.auth.expired.message": {
    id: "alert.auth.expired.message",
    defaultMessage: "Your session has expired.",
  },
  "alert.auth.expired.buttonLogin": {
    id: "alert.auth.expired.buttonLogin",
    defaultMessage: "To continue, sign in to your account.",
  },
});

export const AlertAuthExpired = ({ handleLogin, handleAlertDismiss }) => (
  <Alert
    type={appconfig.alertTypes.DANGER}
    icon={<AlertOutlinedIcon />}
    body={
      <Typography color="type-color-inverted">
        <span className={classes.bannerMessageText}>
          <FormattedMessage {...t["alert.auth.expired.message"]} />
          <span className={classes.secondPartOfText}>
            <FormattedMessage {...t["alert.auth.expired.buttonLogin"]} />
          </span>
        </span>
      </Typography>
    }
    buttons={
      <Button
        id="auth-expired-login"
        size="small"
        variant="neutral"
        className={classes.bannerMessageCTA}
        onClick={handleLogin}
      >
        <Typography color="type-color-inverted">
          <FormattedMessage {...st["shared.signin"]} />
        </Typography>
      </Button>
    }
    handleDismiss={handleAlertDismiss}
  />
);

AlertAuthExpired.propTypes = {
  handleAlertDismiss: PropTypes.func.isRequired,
  handleLogin: PropTypes.func.isRequired,
};

export default AlertAuthExpired;
