/* eslint-env _castle */
import React from "react";
import appconfig from "config/appconfig";
import environment from "lib/environment";

// Function to answer the social account name to display in the UI.
// This conversion function is necessary because we can't use the actual account names
//   in appconfig.socialAcounts as those are aligned with API name/value pairs.

// Function to answer the support link URL - based on the SCIM "supportExperience" value.
export const getSupportExperienceUrls = (supportExperience) => {
  const urls = {
    supportUrl: "",
    faqUrl: "",
  };
  if (supportExperience === appconfig.supportExperiences.DEFAULT) {
    urls.supportUrl = environment.get().support_url;
    urls.faqUrl = environment.get().faq_url;
  } else if (supportExperience === appconfig.supportExperiences.JIVE_ONLY) {
    urls.supportUrl = environment.get().jive_support_url;
    urls.faqUrl = environment.get().jive_faq_url;
  }
  return urls;
};


export const isFauxLocale = (locale) => locale === 'hu' || locale === 'nl' || locale === 'ru';

export const convertToUTC =(date) =>{
  const dateArr = date.split('/');
  const mm = parseInt(dateArr[0], 10);
  const dd = parseInt(dateArr[1], 10);
  const yy = parseInt(dateArr[2], 10);
  return Date.UTC(yy, mm - 1, dd, 0, 0, 0);
};

export const creditCardExpirationDetails = (creditCard) => {
  const cardData = creditCard;

  // find out if card is expred or getting expired within 3 months
  const currDate = new Date();
  const currMonth = currDate.getMonth() + 1;
  const currYear = currDate.getFullYear();

  // Calculate the year and month differences
  const yearDiff = cardData.expirationYear - currYear;
  const monthDiff = cardData.expirationMonth - currMonth;

  // Total months difference
  const totalMonthsDiff = yearDiff * 12 + monthDiff;
  // remove _,- from card type to assign a card class
  cardData.type = cardData.type.replace(/[_-]/g, '');

  if(totalMonthsDiff >=0  && totalMonthsDiff <= 3 ){
    cardData.isCardExpired = false;
    cardData.isCardExpiring = true;
  }
  else if(totalMonthsDiff < 0) {
    cardData.isCardExpired = true;
    cardData.isCardExpiring = false;
  }
  else{
    cardData.isCardExpired = false;
    cardData.isCardExpiring = false;
  }
  return cardData;
};