import api from 'dao/providers/axios';
import appconfig from 'config/appconfig';
import environment from 'lib/environment';

const mtgServiceUrl = { baseURL: environment.get().api.meetingServiceURL };
const mtgServiceSettingsEndpoint = appconfig.endpoints.mtgServiceSettings;
const mtgConferencesUrl = { baseURL: environment.get().api.conferenceServices};
const mtgUserSettingsEndpoint = appconfig.endpoints.mtgUserSetting;
const mtgConferencesEndpoint = appconfig.endpoints.mtgConferences;

export const daoExtMeetingServiceSettingsGet = () => api.get(`${mtgServiceSettingsEndpoint}`, mtgServiceUrl);

export const daoMeetingUserSettingsGet= (userKey) => api.get(mtgUserSettingsEndpoint(userKey), mtgServiceUrl);

export const daoMeetingConferences = (accountKey,params) => api.post(mtgConferencesEndpoint(accountKey),params,mtgConferencesUrl);
