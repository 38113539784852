import React, { useEffect, useState } from'react';
import ProductView from '../components/product-view';
import {meTheme, showAnnouncement, showErrorBanner} from 'modules/ui-module';
import { prodSpecs } from 'lib/product-specs';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import environment from 'lib/environment';
import { 
  meNotificationsData,
  meShowAddLicenseButton,
  meShowAdminSection,
  meShowBillingSection,
  meShowLaunchMDMLink 
} from 'modules/me-module';
import { mtgConferencesGet } from 'modules/meeting-module';

export const ProductContainer =(props) =>{
  const {
    notificationsData,
    meTheme,
    showAddLicenseButton,
    showAdminSection,
    showBillingSection,
    showLaunchMDMLink
  } = useSelector(getValuesFromStore);

  const [addLicense,setAddLicense] = useState({});
  const [productInfo,setProductInfo] = useState({});
  const [isloading,setIsLoading] = useState(true);
  const [instantMeetingDisabled,setInstantMeetingDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(()=>{
    const  subscriptionData  = notificationsData.subscriptionData;
  
    if (subscriptionData && subscriptionData.length > 0) {
      const licenseData = subscriptionData.find(sub => 
        sub.productDisplayName.toLowerCase().includes('gotomeeting') &&
        (sub.subscriptionExpired || sub.subscriptionExpiring || sub.subscriptionExpiringToday)
      );
      if (licenseData) {
        setAddLicense(licenseData);
        dispatch(showAnnouncement(true));
      } else {
        dispatch(showAnnouncement(false));
      }
      setIsLoading(false);
    }
    setIsLoading(false);
    
  },[dispatch ,notificationsData.subscriptionData]);

  useEffect(()=>{
    const productDetails = prodSpecs[props.product];
    if (productDetails) {
      const newProductInfo ={
        prodBold : productDetails.prodBold,
        prodLight : productDetails.prodLight,
        prodClass : productDetails.class,
        linkText : productDetails.linkText,
        prodName : productDetails.product,
        subProdName : productDetails.subProd, 
        showBillingSection : showBillingSection,
        showAdminSection : showAdminSection,
        showJiveAdminContent : 
        ((productDetails.product === 'Jive' 
          || productDetails.product === 'GoToConnect') 
          && (showBillingSection ||showAdminSection) ),
        showAddLicenseButton : productDetails.product === 'GoToMeeting' && showAddLicenseButton ,
        showLaunchMDMLink : productDetails.product === 'GoToResolve' && showLaunchMDMLink,
      };
      setProductInfo(newProductInfo);
    }
  },[props.product,showBillingSection,showAdminSection,showAddLicenseButton,showLaunchMDMLink]);

  const openMeetingTab=(prodName, conferenceId)=>{
    let url;
    if (typeof conferenceId !== 'undefined') {
      url = `${environment.get().product[`${prodName}Start`]}/${conferenceId}`;
    } else {
      url = `${environment.get().product[`${prodName}App`]}`;
    }
    window.open(url, '_blank', 'noopener noreferrer');
  };

  const handleInstantMeetingClickEvent=(prodName)=> {

    setInstantMeetingDisabled(true);
    const acctKey = notificationsData.adminData['adminAccountData'].accountKey;
    const params = JSON.stringify({
      subject: 'Instant Meeting',
      lifecycle: 'impromptu'
    });

    dispatch(mtgConferencesGet(acctKey,params)).then((meetingData) => {
      setInstantMeetingDisabled(false);
      const confId = meetingData.payload.conferenceId || ''; 
      if (confId) {
        return openMeetingTab(prodName, confId);
      }
      if (meetingData.payload.data.errorType && meetingData.payload.data.incidentId) {
        return openMeetingTab(prodName);
      }
      return true;
    }).catch((error) => {
      dispatch(showErrorBanner(true));
      console.error(`error adminData ${error}`);
    });
    return true;
  };

  if(prodSpecs[props.product]){
    return(
      <ProductView
        prod={props.product}
        handleInstantMeetingClickEvent={handleInstantMeetingClickEvent}
        productInfo={productInfo}
        theme={meTheme}
        addLicense={addLicense}
        instantMeetingDisabled={instantMeetingDisabled}
        isloading={isloading}
      />
    );
  }

  return null;
};

ProductContainer.propTypes = {
  product: PropTypes.string.isRequired,
};

export const getValuesFromStore = (state) => {
  return {
    meTheme: meTheme(state),
    notificationsData: meNotificationsData(state),
    showAddLicenseButton: meShowAddLicenseButton(state),
    showAdminSection: meShowAdminSection(state),
    showBillingSection: meShowBillingSection(state),
    showLaunchMDMLink: meShowLaunchMDMLink(state),
  };
};

export default ProductContainer;