import React from "react";
import { useSelector, useDispatch } from "react-redux";
import SideDrawer from "../components/side-drawer";

import { meShowSidePanel ,showSidePanel } from "modules/ui-module";
export const SideDrawerContainer = () => {
  const {
    meShowSidePanel
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();
  
  const onCloseDrawer = () =>{
    dispatch(showSidePanel(''));
    return true;
  };

  return (
    <SideDrawer
      meShowSidePanel={meShowSidePanel}
      onCloseDrawer={onCloseDrawer}
    />
  );
};
export const getValuesFromStore = (state) => {
  return {
    meShowSidePanel: meShowSidePanel(state),
  };
};

export default SideDrawerContainer;
