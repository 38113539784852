import React from'react';
import { 
  meData,
  meSupportExperience, 
  meIsAccountAdmin, 
  meEntitlements, 
  isProductFound, 
  meUnifiedAdminFlag, 
  meHideAdminSection,
  meHideBillingSection} from 'modules/me-module';
import QuickLinksView from '../components/quick-link-view';
import { useSelector } from 'react-redux';
import { meIsBillingAdmin } from 'modules/billing-module';

export const QuickLinksContainer =() =>{
  const { 
    supportExperience ,
    meData , 
    meIsAccountAdmin,
    meIsBillingAdmin,
    isProductFound,
    unifiedAdminFlag,
    hideAdminSection,
    hideBillingSection
  } = useSelector(getValuesFromStore);

  return(
    <QuickLinksView
      hideAdminSection={hideAdminSection}
      hideBillingSection={hideBillingSection}
      meData={meData}
      supportExperience={supportExperience}
      unifiedAdminFlag={unifiedAdminFlag}
      meIsAccountAdmin={meIsAccountAdmin}
      meIsBillingAdmin={meIsBillingAdmin}
      isProductFound={isProductFound}
    />
  );
};

export const getValuesFromStore =(state) =>({
  meEntitlements: meEntitlements(state),
  meData: meData(state),
  supportExperience: meSupportExperience(state),
  meIsAccountAdmin: meIsAccountAdmin(state),
  meIsBillingAdmin: meIsBillingAdmin(state),
  isProductFound : isProductFound(state),
  unifiedAdminFlag: meUnifiedAdminFlag(state),
  hideAdminSection: meHideAdminSection(state),
  hideBillingSection: meHideBillingSection(state),
});

export default QuickLinksContainer;