import React from 'react';
import PropTypes from 'prop-types';
import {
  BellOutlinedIcon,
  ClearAllOutlinedIcon
} from '@getgo/chameleon-icons/react';
import {  EmptyState, Typography ,Button } from '@getgo/chameleon-web-react-wrapper';
import {
  Box, Grid
} from '@mui/material';
import { FormattedMessage, defineMessages } from 'react-intl';
import { NotificationCard } from './notification-card';
import classes from './notification-drawer.scss';

const t = defineMessages({
  "notifications.noNewNotifications": {
    id: "notifications.noNewNotifications",
    defaultMessage: "There are no new notifications.",
  },
  "notifications.new.title": {
    id: "notifications.new.title",
    defaultMessage: "New",
  },
  "notifications.no.notificationsTitle": {
    id: "notifications.no.notificationsTitle",
    defaultMessage: "There was no new admin activity in your account since your last session.",
  },
  "notifications.clear-all.title": {
    id: "notifications.clear-all.title",
    defaultMessage: "Clear all",
  }
});

const notificationKeys = [
  'cardData',
  'subscriptionData',
  'adminData',
  'settingsData',
];

export const ShowNotification =({
  getMonth,
  getClass,
  notificationsCount,
  notificationsData,
  clearNotifications
  })=>{
  return (
    <Box>
      <Box data-section="my-account-notifications">
        {notificationsCount !== 0
          && <Grid container className={classes['gridContainer']}>
            <Grid className={classes['gridContainerNew']} item>
              <Typography>
                <FormattedMessage {...t['notifications.new.title']} /> ({notificationsCount})
              </Typography>
            </Grid>
            <Grid className={classes['gridContainerClearAll']} item>
            <Button
                leadingIcon={<ClearAllOutlinedIcon />}
                variant="tertiary"
                onClick={clearNotifications}
              >
                <FormattedMessage {...t['notifications.clear-all.title']} />
              </Button>
            </Grid>
          </Grid>}
        {notificationKeys.map((key) => 
          notificationsData[key] && (
            <Box className={classes["notification__content"]} key={key}>
              {notificationsData[key].map((data, i) => (
                <NotificationCard 
                  key={`${key}-${i}`} 
                  notificationData={data} 
                  getClass={getClass} 
                  getMonth={getMonth} 
                />
              ))}
            </Box>
          )
        )}
        {notificationsCount === 0
          && <Box className={classes['noNewNotifications']} top="30%" flex={1} p={5}>
            <EmptyState icon={<BellOutlinedIcon />} title="No Notifications">
              <FormattedMessage {...t['notifications.no.notificationsTitle']} />
            </EmptyState>
          </Box>}
      </Box>
    </Box>
  );
};

ShowNotification.propTypes = {
  getMonth: PropTypes.func.isRequired,
  getClass: PropTypes.func.isRequired,
  notificationsCount: PropTypes.number.isRequired,
  notificationsData: PropTypes.object.isRequired,
  clearNotifications: PropTypes.func.isRequired,
};
export default ShowNotification;
