import React  from "react";
import { FormattedMessage } from 'react-intl';
import environment from 'lib/environment';
import { Button, Typography } from "@getgo/chameleon-web-react-wrapper";
import {isEmpty} from 'lodash';
import classes from './product-view.scss';
import st from 'core/shared-translations';
import PropTypes from 'prop-types';
import { Box } from "@mui/material";

export const RenderLicense = (props) => {

  const subscriptionData = props.addLicense;

  if (!isEmpty(subscriptionData)) {
    // subscriptionData object is only available in this fn if the user account is a trial account
    const prodName = 'GoToMeeting';
      let announcementText;
      let daysRemaining;
      if (subscriptionData.subscriptionExpiring === true) {
        announcementText = 'shared.trialExpiring-Header';
        daysRemaining= subscriptionData.daysRemainingInTerm;
      } else if (subscriptionData.subscriptionExpiringToday === true) {
        announcementText = 'shared.trialExpiringToday-Header';
      } else if (subscriptionData.subscriptionExpired === true) {
        announcementText = 'shared.trialExpired-Header';
      }

    return (
      <Box>
        {announcementText ?
          <Box className={classes['addlicense']}>
            <Typography>
              <FormattedMessage {...st[announcementText]} values={{ productDisplayName: prodName , daysRemainingInTerm: daysRemaining }} />
            </Typography>
            <a
              data-wa-link=""
              href={environment.get().help.billing}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Button style={{ paddingTop: '5px' }} variant="tertiary">
                <FormattedMessage {...st['shared.announcement.GoToMeeting.link']} />
              </Button>
            </a>
          </Box>:null}
      </Box>
    );
  }
  else return null;
};
RenderLicense.propTypes= {
  addLicense: PropTypes.object.isRequired,
 };

export default RenderLicense;
