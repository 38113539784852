import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import MainNav from "../components/main-nav-view";
import {
  meId,
  meProducts,
  meIsAccountAdmin,
  meUserName,
  meDisplayName,
  meNewAvatarImageSource,
  meSupportExperience,
  meLocale,
  meData,
  meNotificationsCount,
  meUpateNotificationsCount,
  meUpdateNotificationsData,
  meShowEnhancedSecurity,
  meShowGotoBanner,
  meG2mProduct,
} from "modules/me-module";
import {
  meShowSidePanel,
  meShowUnreadLogo,
  meTheme,
  setTheme,
  showSidePanel,
  showUnreadLogo,
  showErrorBanner,
  showGoToAppBanner
} from "modules/ui-module";
import auth from "lib/myaccount-auth";
import environment from "lib/environment";
import { billingMeRequestGet, meIsBillingAdmin } from "modules/billing-module";
import { mtgServiceSettingsGet, gotoAppEnabled } from "modules/meeting-module";
import appconfig from "config/appconfig";
import { getSupportExperienceUrls} from "lib/utility-functions";
import { AdminMeRequestGet  } from 'modules/extadmin-module';

export const MainNavContainer = () => {
  const {
    logout,
    myProducts,
    displayName,
    avatarImageUrl,
    isBillingAdmin,
    isAccountAdmin,
    displayEmail,
    supportExperience,
    isGotoAppEnabled,
    language,
    meShowSidePanel,
    meShowUnreadLogo,
    meData,
    meNotificationsCount,
    meSupportExperience,
    meTheme,
    showEnhancedSecurity,
    showGotoBanner,
    isG2mProduct
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = React.useState(false);

  const toggleMenuVisibility = () => {
    const displayMenu = !showMenu;
    setShowMenu(displayMenu);
    if(showMenu){
      dispatch(showSidePanel(''));
    }
    else dispatch(showSidePanel('MobileAvatarView'));
    return;
  };

  const theme = () =>{
    //returns light / dark / os 
    // metheme returns light / os
    return localStorage.getItem('THEME');
  };

  const notificationsClick = () =>{    
    dispatch(showSidePanel('Notifications'));
    dispatch(showUnreadLogo(false));
  };

  const themeClick = (theme) =>{
    localStorage.setItem('THEME', theme);
    dispatch(setTheme(theme));
    return false;
  };

  const helpIconClick = () =>{
      dispatch(showSidePanel('Help'));
  };

  const handleGoToAppFlag = useCallback(( adminAccountData, newAdminData)=> {
    const userKey = adminAccountData.key;

    dispatch(mtgServiceSettingsGet(userKey)).then((adminData) => {
      // we check this flag only for G2M paid customers
      const gotoAppEntitledFlag = adminData.payload.gotoAppEntitled || false;
      const gotoAppEnabledFlag = adminData.payload.gotoAppEnabled || false;
      newAdminData.gotoAppEnabledFlag = gotoAppEnabledFlag;
      newAdminData.adminAccountData = adminAccountData;
      // display the download GoTo App banner
      if (gotoAppEntitledFlag && !gotoAppEnabledFlag && showGotoBanner) {
        dispatch(showGoToAppBanner(true));
      }
      dispatch(meUpdateNotificationsData(newAdminData,'admin'));
    }).catch((error) => {
      dispatch(showErrorBanner(true));
      console.error(`error adminData ${error}`);
    });
  },[dispatch,showGotoBanner]);

  const handleAdminInvitation = useCallback(()=> {
    dispatch(AdminMeRequestGet()).then((adminData) => {
      const adminAccountData = adminData.payload;
      const adminAccounts = adminAccountData.accounts || [];
      let newAdminData = [];
      // we need to check all the accounts to see if invitation is present
      for (let i = 0; i < adminAccounts.length; i++) {
        // invitation key will only be present in response if user has a pending invitation
        const account = adminAccounts[i];
        if (account.invitation) {
          // update the userData with the our custom admin data
          newAdminData = [...newAdminData, { ...account }];
          // update the notifications data
          dispatch(meUpateNotificationsCount('increament'));
          dispatch(showUnreadLogo(true));
        }
      }
     // we check this flag only for G2M paid customers
      if (adminData.payload.products && adminData.payload.products.includes('G2M')) {
        handleGoToAppFlag(adminAccountData, newAdminData);
      } else {
        dispatch(meUpdateNotificationsData(newAdminData,'admin'));
      }
    }).catch((error) => {
      dispatch(showErrorBanner(true));
      console.error(`error adminData ${error}`);
    });
  },[dispatch,handleGoToAppFlag]);

  useEffect(() => {
    dispatch(billingMeRequestGet());
    if (isG2mProduct) {
      dispatch(mtgServiceSettingsGet());
    }
    if (showEnhancedSecurity) {
      dispatch(meUpdateNotificationsData({ setting: 'ENHANCED_SECURITY' },'settings'));
      dispatch(meUpateNotificationsCount('increament'));
      dispatch(showUnreadLogo(true));
    }
    handleAdminInvitation();
  }, [dispatch ,showEnhancedSecurity,isG2mProduct,handleAdminInvitation]);


  return (
    <MainNav
      logout={logout}
      displayName={displayName}
      meIsAccountAdmin={isAccountAdmin}
      myProducts={myProducts}
      avatarImageUrl={avatarImageUrl}
      meIsBillingAdmin={isBillingAdmin}
      showSupportLink={supportExperience !== appconfig.supportExperiences.HIDE}
      supportUrls={getSupportExperienceUrls(supportExperience)}
      gotoAppEnabled={isGotoAppEnabled}
      language={language}
      displayEmail={displayEmail}
      showMenu={showMenu}
      toggleMenuVisibility={toggleMenuVisibility}
      notificationsClick={notificationsClick}
      helpIconClick={helpIconClick}
      sidePanel={meShowSidePanel}
      theme={theme}
      meTheme={meTheme}
      meData={meData}
      notificationsCount={meNotificationsCount}
      themeClick={themeClick}
      meShowUnreadLogo={meShowUnreadLogo}
      meSupportExperience={meSupportExperience}
    />
  );
};
export const getValuesFromStore = (state) => {
  // Adding a default value for environment.get().avatar_image_url,
  //   because during unit testing a false warning message is thrown without it.
  const avatarBaseImageUrl = environment.get().avatar_image_url || "";
  let avatarImageUrl = avatarBaseImageUrl.length
    ? `${avatarBaseImageUrl}${meId(state)}_medium.jpg`
    : "";
  // Check if the user has uploaded a new avatar image during the session.
  //   If he/she did, use the URL of that image as a source for the mini avatar.
  const newAvatarImage = meNewAvatarImageSource(state);
  if (newAvatarImage) {
    avatarImageUrl = newAvatarImage;
  }

  return {
    logout: auth.logout.bind(auth),
    myProducts: meProducts(state),
    displayName: meDisplayName(state) || "",
    avatarImageUrl,
    isBillingAdmin: meIsBillingAdmin(state),
    isAccountAdmin: meIsAccountAdmin(state),
    currentPath: window.location.pathname,
    displayEmail: meUserName(state) || "",
    supportExperience: meSupportExperience(state) || appconfig.supportExperiences.DEFAULT,
    meSupportExperience: meSupportExperience(state),
    isGotoAppEnabled: gotoAppEnabled(state),
    language: meLocale(state)
      ? meLocale(state).split("_")[0].toLowerCase()
      : "en",
    meShowSidePanel: meShowSidePanel(state),
    meTheme: meTheme(state),
    meData: meData(state),
    meNotificationsCount: meNotificationsCount(state),
    meShowUnreadLogo: meShowUnreadLogo(state),
    showEnhancedSecurity: meShowEnhancedSecurity(state),
    showGotoBanner : meShowGotoBanner(state),
    isG2mProduct: meG2mProduct(state),
  };
};

export default MainNavContainer;
