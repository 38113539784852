import React from "react";
import { useSelector} from "react-redux";
import ShowNotification from "../components/notification-drawer";
import {
  meNotificationsData,
  meUpateNotificationsCount,
  meUpdateNotificationsData,
} from "modules/me-module";
import { meNotificationsCount } from "modules/me-module";
import { useDispatch } from "react-redux";
import { cardSpecs ,months } from "lib/constants";

export const NotificationDrawerContainer = () => {
  const {
    notificationsCount,
    notificationsData
  } = useSelector(getValuesFromStore);

  const dispatch = useDispatch();

  const getMonth = (value) =>{
    const month = (months[value - 1]);
    return month;
  };

  const getClass= (cardType)=>{
    return (cardType in cardSpecs) ? cardSpecs[cardType] : 'id-card';
  };

  const clearNotifications = () =>{
    dispatch(meUpdateNotificationsData({},'empty'));
    dispatch(meUpateNotificationsCount('clear'));
  };

  return (
    <ShowNotification
      getMonth={getMonth}
      getClass={getClass}
      notificationsCount={notificationsCount}
      notificationsData={notificationsData}
      clearNotifications={clearNotifications}
    />
  );
};
export const getValuesFromStore = (state) => {
  return {
    notificationsCount: meNotificationsCount(state),
    notificationsData : meNotificationsData(state)
  };
};

export default NotificationDrawerContainer;
