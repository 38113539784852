import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { Banner, Button } from '@getgo/chameleon-web-react-wrapper';
import { AlertFilledIcon } from '@getgo/chameleon-icons/react';
import environment from 'lib/environment';
import { showErrorBanner } from 'modules/ui-module';
import { useDispatch } from 'react-redux';

const t = defineMessages({
  "error.banner.buttonContent": {
    id: "error.banner.buttonContent",
    defaultMessage: "Reload page",
  },
  "error.banner.errorContent": {
    id: "error.banner.errorContent",
    defaultMessage: "We were unable to load some elements on this page.",
  },
});

export const ErrorBanner =()=> {
  const dispatch = useDispatch();

  return (
    <Banner
      variant="neutral"
      action={
        <Button 
          variant="inverted" 
          className="download-gotoapp-button" 
          data-testid="error-button"
          id="error-button"
          onClick={() => { window.location.href= environment.get().api.redirect_url; }}>
          <FormattedMessage {...t['error.banner.buttonContent']} />
        </Button>
      }
      icon={<AlertFilledIcon />}
      onClose={()=>{ dispatch(showErrorBanner(false));}}
      closable
    >
      <FormattedMessage {...t['error.banner.errorContent']} />
    </Banner>
  );   
};

export default ErrorBanner;
