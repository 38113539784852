import React from 'react';
import { ExternalLinkOutlinedIcon } from '@getgo/chameleon-icons/react';
import { Menu, MenuItem } from '@getgo/chameleon-web-react-wrapper';
import { Box } from '@mui/material';
import environment from 'lib/environment';
import { useSelector } from 'react-redux';
import { meSupportExperience } from 'modules/me-module';
import classes from './help-drawer.scss';
import { FormattedMessage } from 'react-intl';
import st from 'core/shared-translations';

const helpHandledByProductSupport = environment.get().help.handledByProductSupport;
const help = environment.get().help.support;
const terms = environment.get().footer.terms;
const about_us = environment.get().footer.aboutUs;
const policy = environment.get().footer.policy;


export const HelpDrawer = () => {
  const {
    supportExperience,
  } = useSelector(getValuesFromStore);

  const topLinks = [
    {
      key: 'get-help',
      title: <FormattedMessage {...st['shared.help.get-help']} />,
      href: supportExperience && supportExperience.handledByProduct ? helpHandledByProductSupport : help,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'support',
      title: <FormattedMessage {...st['shared.help.support']} />,
      href: supportExperience && supportExperience.handledByProduct ? helpHandledByProductSupport : help,
      icon: <ExternalLinkOutlinedIcon />
    },
  ];

  const bottomLinks = [
    {
      key: 'terms-of-service',
      title: <FormattedMessage {...st['shared.navbar.termsOfService']} />,
      href: terms,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'privacy-policy',
      title: <FormattedMessage {...st['shared.navbar.privacyPolicy']} />,
      href: policy,
      icon: <ExternalLinkOutlinedIcon />
    },
    {
      key: 'about-us',
      title: <FormattedMessage {...st['shared.navbar.aboutUs']} />,
      href: about_us,
      icon: <ExternalLinkOutlinedIcon />
    }
  ];

  return (
    <Box>
      <Menu label="help-drawer-top-link">
        <Box>
          {topLinks.map((
            {
              key, title, icon, href
            }) => (
              <MenuItem
                key={key}
                trailingIcon={icon}
                onClick={() => {
                window.open(href);
                }}
            >
                {title}
              </MenuItem>
          ))}
        </Box>
      </Menu>
      <Menu label="help-drawer-bottom-links">
        <Box className={classes['bottomLinks']}>
          {bottomLinks.map((
            {
              key, title, icon, href
            }) => (
              <MenuItem
                key={key}
                trailingIcon={icon}
                onClick={() => {
                window.open(href);
                }}
            >
                {title}
              </MenuItem>
          ))}
        </Box>
      </Menu>
    </Box>
  );
};

export const getValuesFromStore = (state) => {
  return {
    supportExperience: meSupportExperience(state),
  };
};

export default HelpDrawer;
