import React from "react";
import PropTypes from "prop-types";
import {
  FormattedMessage,
  defineMessages,
} from "react-intl";
import errorImage from "static/images/error-robot.svg";
import classes from "./error-view.scss";
import { Box } from "@mui/material";

const statusCodes = {
  400: "Bad Request",
  401: "Unauthorized",
  403: "Forbidden",
  404: "Not Found",
  405: "Method Not Allowed",
  406: "Not Acceptable",
  409: "Conflict",
  415: "Unsupported Media Type",
  422: "Unprocessable Entity",
  500: "Internal Server Error",
  502: "Bad Gateway",
  503: "Service Unavailable",
  507: "Insufficient Storage",
};

export const ErrorView = ({ errorUrl, error }) => {
  const t = defineMessages({
    "common.error.header": {
      id: "common.error.header",
      defaultMessage: "Error! We’ve encountered a problem!",
    },
    "common.error.link": {
      id: "common.error.link",
      defaultMessage:
        'Please try again later or go back to <a href="{href}" id="my-account-link">your account</a>.',
    },
    "common.error.details": {
      id: "common.error.details",
      defaultMessage: "Error Details",
    },
    "common.error.id": {
      id: "common.error.id",
      defaultMessage: "Error ID",
    },
    "common.error.code": {
      id: "common.error.code",
      defaultMessage: "Code",
    },
    "common.error.status": {
      id: "common.error.status",
      defaultMessage: "Status",
    },
    "common.error.field": {
      id: "common.error.field",
      defaultMessage: "Field",
    },
    "common.error.value": {
      id: "common.error.value",
      defaultMessage: "Value",
    },
    "common.error.debug": {
      id: "common.error.debug",
      defaultMessage: "Debug",
    },
  });

  return (
    <Box id="error-view" className={classes["error-view"]}>
      <Box className={classes["alignedContent"]}>
        <Box className="col-sm-offset-2 col-sm-8 col-xs-12">
          <img src={errorImage} width="286" height="269" alt={<FormattedMessage {...t["common.error.header"]} />} />
        </Box>
      </Box>
      <Box className={classes["alignedContent"]}>
        <Box className="col-sm-offset-2 col-sm-8 col-xs-12">
          <h2>
            <FormattedMessage {...t["common.error.header"]} />
          </h2>
        </Box>
      </Box>
      <Box className={classes["alignedContent"]}>
        <Box className="col-sm-offset-3 col-sm-6 col-xs-12">
          <h5>
            <FormattedMessage
              {...t["common.error.link"]}
              values={{ href: errorUrl }}
            />
          </h5>
        </Box>
      </Box>
      {error && error.data && (
        <Box className={`row ${classes["error-details"]}`}>
          <Box className="col-xs-12 col-sm-8 col-sm-offset-2">
            <h5>
              <FormattedMessage {...t["common.error.details"]} />
            </h5>
            <hr />
            {error.data.id &&
              <Box className={classes["errorInfo"]}>
                <Box className="col-xs-4 text-right">
                  <FormattedMessage {...t["common.error.id"]} />
                </Box>
                <Box className="col-xs-8 text-left">
                  <strong>{error.data.id}</strong>
                </Box>
              </Box>}
            {error.status &&
              <Box className={classes["errorInfo"]}>
                <Box className="col-xs-4 text-right">
                  <FormattedMessage {...t["common.error.status"]} />
                </Box>
                <Box className="col-xs-8 text-left">
                  <strong id="error-status-code">
                    {statusCodes[error.status] || error.status}
                  </strong>
                </Box>
              </Box>}
            {Array.isArray(error.data.errors) &&
              error.data.errors.map((errorData) => (
                <Box id="errors" key={errorData.code}>
                  {errorData.code && (
                    <Box className={classes["errorInfo"]}>
                      <Box className="col-xs-4 text-right">
                        <FormattedMessage {...t["common.error.code"]} />
                      </Box>
                      <Box className="col-xs-8 text-left">
                        <strong className="error-code">{errorData.code}</strong>
                      </Box>
                    </Box>
                  )}
                  {errorData.field && (
                    <Box className={classes["errorInfo"]}>
                      <Box className="col-xs-4 text-right">
                        <FormattedMessage {...t["common.error.field"]} />
                      </Box>
                      <Box className="col-xs-8 text-left">
                        <strong className="error-field">
                          {errorData.field}
                        </strong>
                      </Box>
                    </Box>
                  )}
                  {errorData.value && (
                    <Box className={classes["errorInfo"]}>
                      <Box className="col-xs-4 text-right">
                        <FormattedMessage {...t["common.error.value"]} />
                      </Box>
                      <Box className="col-xs-8 text-left">
                        <strong className="error-value">
                          {errorData.value}
                        </strong>
                      </Box>
                    </Box>
                  )}
                  {errorData.debug && (
                    <Box className={classes["errorInfo"]}>
                      <Box className="col-xs-4 text-right">
                        <FormattedMessage {...t["common.error.debug"]} />
                      </Box>
                      <Box className="col-xs-8 text-left">
                        <strong className="error-debug">
                          {errorData.debug}
                        </strong>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
            <hr />
          </Box>
        </Box>
      )}
    </Box>
  );
};

ErrorView.propTypes = {
  errorUrl: PropTypes.string.isRequired,
  error: PropTypes.object,
};

export default ErrorView;
